/**
 * Created by andyosullivan on 04/04/2021.
 */
import React, { Component } from "react";
import "./Construction.css";
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import towtruck from '../../assets/images/digger.png';
import Card from 'react-bootstrap/Card';


export default class Construction extends Component {

    componentDidMount () {
        this.deleteAllCookies();
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear()
        sessionStorage.clear();
    }

    render() {
        return (
            <div className="divmor">
                <Container>

                    <Row className="blurbRow">

                        <h1 className="title">noadshere.com<br/>under construction</h1>

                    </Row>
                    <Row className="blurbRow">


                        <div className="towtruck">
                            <img src={towtruck} alt="cartoon tow truck"/>
                        </div>

                    </Row>

                    <Row className="blurbRow">

                        <Card className="blurbCard">
                            <h2 className="title">a little corner of the internet with no ads, no tracking, no cookies,
                                no pretending to value your privacy but really just wanting to sell your data, none of that.</h2><br/>
                        </Card>
                    </Row>
                </Container>
            </div>
        );
    }
}

/*


 Home

 About

 Recipes

 Movie Reviews

 App Development

 Serverless ?

 *//**
 * Created by andyosullivan on 19/06/2021.
 */
