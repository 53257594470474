/**
 * Created by andyosullivan on 20/06/2021.
 */
import React, { Component } from "react";
import "../Home/Home.css";
import "./About.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import logo from '../../assets/images/noadsherelogo.jpg';

export default class About extends Component {

    componentDidMount () {
        this.deleteAllCookies();
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear()
        sessionStorage.clear();
    }

    render() {
        return (
            <div className="divmor">
                <Container>

                    <Row className="blurbRow">


                        <Col className="" sm={12} lg={8}>
                            <div className="aboutDiv">
                                <h1 className="darkGrey">about</h1>
                                <p className="aboutBlurb"> NOadsHERE.com is a new site created as a counter balance
                                    to the ad-driven internet. This site has no ads, no user tracking, no user analytics,
                                    no affiliate marketing, no cookies, no cost, no subscriptions, no paywalls - it's a
                                    simple, free site to host normal content like Movie Reviews and Recipes, just without
                                    all the ads and privacy invasion stuff you see on other sites.
                                    <br/><br/>
                                    There's no monetisation of content - so there is no incentive for click-bait headlines
                                    or content. We're hoping it can become a place folks can read interesting content
                                    in a nice environment.
                                </p>
                                <br/>
                                <h1 className="darkGrey">no ads or monetisation at all?</h1>
                                <p className="aboutBlurb"> zero.
                                </p>
                                <br/>
                                <h1 className="darkGrey">how do you pay for the run costs?</h1>
                                <p className="aboutBlurb"> The site is 'serverless' - built and hosted in a low-cost fashion
                                    on a cloud platform. If for some reason the site gets really popular we may need
                                    to look for help paying for it, but we'll cross that bridge when we get to it.
                                </p>
                                <br/>
                                <h1 className="darkGrey">what content will you have?</h1>
                                <p className="aboutBlurb"> Just normal stuff - Movie Reviews, Recipes, Tech Tutorials and whatever
                                    else folks want to write about. We included recipes from the start as we're just fed up
                                    of recipe sites that are destroyed with ads and pad-out the text with back stories just to fit
                                    in more ads. If you want a recipe for nacho cheese sauce, without a lengthy backstory
                                    on how a grandmother moved from Sicily to New York to start a new life, noadshere is the
                                    place to be.
                                </p>
                                <br/>
                                <h1 className="darkGrey">what about news or current affairs?</h1>
                                <p className="aboutBlurb"> We don't have any news content to start with, but may include some
                                    over time if folks want to write some. We're very much interested in countering fake-news,
                                    anti-vaxxers, bad big tech and authoritarian government propaganda - so will likely
                                    have related content over time.
                                </p>
                                <br/>
                                <h1 className="darkGrey">who writes the posts?</h1>
                                <p className="aboutBlurb"> So far it's me (Andy O'Sullivan), one of my daughters, and one of my friends (Ellen Ward)! You may have noticed
                                    the Spiderman No Way Home review is a "Way Too Short Teenage Review"! For kids who
                                    can spend all day texting their friends, it's remarkably difficult to get them to write
                                    full reviews on anything!
                                    <br/><br/>We are of course looking for more writers, our dream is that site becomes a community where
                                    folks can post and read content, without any of the other crap we see on other sites.
                                </p>
                                <br/>
                                <h1 className="darkGrey">how can you write posts?</h1>
                                <p className="aboutBlurb"> If you're interested in writing for noadshere.com,
                                    fill out this <a href="https://forms.gle/ZhXkvuDtN54n7r1H9" target="_blank" rel="noreferrer" >Google form </a>
                                    and we'll get back to you. We realise that me and my family will never have time to write loads of content,
                                    but we're hoping others get inspired to get involved in what we're trying to achieve - a nice, clear no-ads website!
                                </p>
                                <br/>
                                <h1 className="darkGrey">will you get paid to write posts?</h1>
                                <p className="aboutBlurb"> No - there's no monetisation, so there's no $$$ to pay writers.
                                </p>
                                <br/>
                                <h1 className="darkGrey">is this not exploitative and trying to get folks to work for free?</h1>
                                <p className="aboutBlurb"> We don't make any money writing on the site either!
                                </p>
                                <br/>
                                <h1 className="darkGrey">can I link to my own website / social profiles from my posts?</h1>
                                <p className="aboutBlurb"> Of course - all writers can post links to their personal pages / social profiles.
                                </p>
                                <br/>
                                <h1 className="darkGrey">will I own the copyright of my posts?</h1>
                                <p className="aboutBlurb"> Of course - all writers' posts are theirs. You can post the content
                                    anywhere else you want to aswell, and you can ask for your posts to be removed at any time.
                                </p>
                                <br/>
                                <h1 className="darkGrey">are there writing guidelines?</h1>
                                <p className="aboutBlurb"> Yes - mainly the obvious stuff: nothing dodgy, no fake-news, no unsubstantiated
                                    criticism, no copyright infringement, no click-bait stuff etc.
                                    We'll share best-practice guidelines for writing aswell, we won't want to turn writers away
                                    but will expect a certain level of decent writing.
                                </p>
                                <br/>
                                <h1 className="darkGrey">are other languages welcome?</h1>
                                <p className="aboutBlurb"> Absolutely - we'd the site to be as diverse as possible - content, writers, languages
                                    & more. Cinnte, beidh rudaí as gaeilge!
                                </p>
                                <br/>
                                <h1 className="darkGrey">why aren't there any capital letters at the start of these questions?!</h1>
                                <p className="aboutBlurb"> we're beginning to ask ourselves that aswell.
                                </p>
                                <br/>
                                <h1 className="darkGrey">why aren't there any user accounts?</h1>
                                <p className="aboutBlurb"> We don't want any user tracking at all - so users of this site can know
                                    there's no data about their visits being stored anywhere.
                                </p>
                                <br/>
                                <h1 className="darkGrey">how will you know if you have any visitors?</h1>
                                <p className="aboutBlurb"> We're going to try assess user visitors from backend logs - i.e. how much
                                    network traffic to the site have we been getting.
                                </p>
                                <br/>
                                <h1 className="darkGrey">is the site looking for technical help?</h1>
                                <p className="aboutBlurb"> Maybe! If you're interested in helping with the technical build drop us a mail,
                                    but we may not need any help unless we start getting a tonne of users and need help scaling etc.
                                </p>
                                <br/>
                                <h1 className="darkGrey">if you don't want to write, but want to help some other way?</h1>
                                <p className="aboutBlurb"> Spread the news! Tell folks about the site!
                                </p>
                                <br/>
                                <h1 className="darkGrey">who runs the site?</h1>
                                <p className="aboutBlurb">I'm Andy O'Sullivan, innovation engineer for Liberty IT, engineer-in-residence
                                    for Tech for Good Dublin, and independent game-developer.
                                    I won't be promoting my games on this site however ... as there's no ads! Even for me!
                                </p>
                                    <br/>

                                <h1 className="darkGrey">why can't I share posts to Facebook?</h1>
                                <p className="aboutBlurb">We're working on it! It requires Server Side Rendering, so we're pondering on
                                    whether to do it!
                                </p>
                                <br/>
                                    <h1 className="darkGrey">contact details?</h1>
                                    <p className="aboutBlurb">You can connect with
                                    me at <a href="https://twitter.com/LeMarquisOfAndy" target="_blank" rel="noreferrer">Twitter </a>
                                    or <a href="https://www.linkedin.com/in/andy-o-sullivan-9673019/" target="_blank" rel="noreferrer" >LinkedIn </a>
                                    or you can email me at <a className="links" href="mailto:andy@toraiocht.com">andy@toraiocht.com</a>.
                                    <br/><br/>
                                    We also have a <a href="https://twitter.com/noadshere" target="_blank" rel="noreferrer"> Twitter account </a>
                                    for the site if you want to follow or share.
                                </p>
                                <br/>
                                <a href="/"> <img className="logo" src={logo} alt="noadshere.com text logo"/></a>
                            </div>
                        </Col>
                        <Col className="" sm={12} lg={4}>
                            <div className="wotDiv">
                                <a href="/"> <img className="logo logo2" src={logo} alt="noadshere.com text logo"/></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="footerAbout">
                    <a className="footerLinks" href="/">home</a>
                    <a className="footerLinks" href="/tv/all">tv</a>
                    <a className="footerLinks" href="/movies/all">movies</a>
                    <a className="footerLinks" href="/recipes/all">recipes</a>
                    <a className="footerLinks" href="/about/">about</a>
                </div>
            </div>
        );
    }
}

/*


 Home

 About

 Recipes

 Movie Reviews

 App Development

 Serverless ?

 */
