/**
 * Created by andyosullivan on 20/06/2021.
 */
import React, { Component } from "react";
import "../Home/Home.css";
import "../About/About.css";
import "../Movies/Movies.css";
import "../Recipes/Recipes.css";
import "./TV.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import {withRouter} from 'react-router-dom';
import { tv_details } from '../../assets/tv/tv_details';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import twitter from "../../assets/images/twitter.png";
import { FacebookShareButton, FacebookIcon } from 'react-share';


class TV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tv: {
                "name": "test",
                "tv": "test-recipe",
                "date": "11/Jan/22",
                "reviewer":{name:"Test",twitter:"test twitter"},
                "wifereview": "test",
                "kidsreview": "test",
                "image_1": "dune001.jpg",
                "image_2": "dune001.jpg",
                "image_3": "dune001.jpg",
                "image_4": "dune001.jpg",
                "image_5": "dune001.jpg",
                "image_6": "dune001.jpg",
                "paragraph_1":"test",
                "paragraph_2":"test",
                "paragraph_3":"test",
                "paragraph_5":"test ",
                "paragraph_6":"test",
                "paragraph_7":"",
                "paragraph_8":"",
                "paragraph_9":"",
                "paragraph_10":"",
                "darkTitle":false,
            },
            tvEpisodes: [],
            allTV: true
        };
        this.filterArray = this.filterArray.bind(this);
    }

    /*componentDidMount () {
        this.deleteAllCookies();

        const tv = this.props.match.params.tv;
        if (tv === "all") {
            this.setState({"allTV":true});
        } else {
            let result = (tv_details.filter(function (o) {
                return o.tv === tv;
            }));
            this.setState({tv: result[0]});
            this.setState({"allTV":false});
            this.setState({url: "https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20on%20https://NOadsHERE.com/tv/"});

        }
        this.setState({tvEpisodes: tv_details});
    }*/

    componentDidMount () {
        this.deleteAllCookies();

        const { tv } = this.props.match.params;
        const allTV = tv === "all";
        let tvDetails = [];

        if (!allTV) {
            tvDetails = tv_details.filter(o => o.tv === tv);
        }

        this.setState({
            allTV,
            tv: tvDetails[0],
            url: allTV ? undefined : "https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20on%20https://NOadsHERE.com/tv/",
            tvEpisodes: tv_details,
        });
    }




    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear();
        sessionStorage.clear();
    }

    filterArray(e){
        var searched = tv_details.filter(({tv}) => tv.includes(e.target.value.toLowerCase()));
        this.setState({tvEpisodes: searched});
    }

    render() {
        let temp = false;
        let temp2 = false;
        let temp3 = false;
        try {
            if (require("../../assets/images/tv/" + this.state.tv.image_2.image)) {
                temp = true;
            }
        }catch (error){
            temp = false;
        }
        try {
            if (require("../../assets/images/tv/" + this.state.tv.image_3.image)) {
                temp2 = true;
            }
        }catch (error){
            temp2 = false;
        }
        try {
            if (require("../../assets/images/tv/" + this.state.tv.image_4.image)) {
                temp3 = true;
            }
        }catch (error){
            temp3 = false;
        }

        return (
            <div className="divmor">
                <Container>
                    <Row className="movieRow">
                        {!this.state.allTV &&
                        <div>
                            <Col className="" sm={12} lg={8}>
                                <div className="movieDiv">
                                    <h1 className="darkGrey">{this.state.tv.name}<br/>
                                        {this.state.tv.subtitle && <p className="subTitle"> {this.state.tv.subtitle}</p>}
                                        <span className="movieDate">{this.state.tv.date} - {this.state.tv.reviewer.twitter && <a href={this.state.tv.reviewer.twitter} rel="noopener noreferrer" target="_blank">{this.state.tv.reviewer.name}</a>}{!this.state.tv.reviewer.twitter && this.state.tv.reviewer.name}</span></h1>
                                    <figure>
                                    <img className="movieImage"
                                         src={require("../../assets/images/tv/" + this.state.tv.image_1.image).default}
                                         alt={this.state.tv.image_1.text}/>
                                        <figcaption className="fig"><h4>{this.state.tv.image_1.text}</h4></figcaption>
                                    </figure>
                                    <br/>
                                    <p className="movieBlurb"> {this.state.tv.paragraph_1}
                                        <br/>
                                    </p>
                                    <p className="movieBlurb"> {this.state.tv.paragraph_2}</p>
                                    <br/>
                                    {temp &&
                                        <div>
                                            <figure>
                                                <img className="movieImage"
                                                     src={require("../../assets/images/tv/" + this.state.tv.image_2.image).default}
                                                     alt={this.state.tv.image_2.text}/>
                                                <figcaption className="fig"><h4>{this.state.tv.image_2.text}</h4></figcaption>
                                                <br/>
                                            </figure>
                                        </div>
                                    }
                                    {this.state.tv.paragraph_3 && <p className="movieBlurb"> {this.state.tv.paragraph_3}</p>}
                                    {this.state.tv.paragraph_4 && <p className="movieBlurb"> {this.state.tv.paragraph_4}</p>}

                                    {temp2 &&
                                        <div>
                                            <br/>
                                            <figure>
                                                <img className="movieImage"
                                                     src={require("../../assets/images/tv/" + this.state.tv.image_3.image).default}
                                                     alt="whatever"/>
                                                <figcaption className="fig"><h4>{this.state.tv.image_3.text}</h4></figcaption>
                                                <br/>
                                            </figure>
                                        </div>
                                    }
                                    {this.state.tv.paragraph_5 && <p className="movieBlurb"> {this.state.tv.paragraph_5}</p>}
                                    {temp3 &&
                                        <div>
                                            <br/>
                                            <figure>
                                                <img className="movieImage"
                                                     src={require("../../assets/images/tv/" + this.state.tv.image_4.image).default}
                                                     alt="whatever"/>
                                                <figcaption className="fig"><h4>{this.state.tv.image_4.text}</h4></figcaption>
                                                <br/>
                                            </figure>
                                        </div>
                                    }
                                    {this.state.tv.paragraph_6 && <p className="movieBlurb"> {this.state.tv.paragraph_6}</p>}
                                    {this.state.tv.paragraph_7 && <p className="movieBlurb"> {this.state.tv.paragraph_7}</p>}
                                    {this.state.tv.paragraph_8 && <p className="movieBlurb"> {this.state.tv.paragraph_8}</p>}
                                    {this.state.tv.paragraph_9 && <p className="movieBlurb"> {this.state.tv.paragraph_9}</p>}
                                    <p className="movieBlurb"><strong>Rating:</strong> {this.state.tv.rating} stars</p>
                                    <p className="movieBlurb sources">All images courtesy of: <a href={this.state.tv.image_sources} rel="noopener noreferrer" target="_blank">{this.state.tv.image_sources}</a> </p>

                                    <p className="movieBlurb"><strong>Share this post on:</strong></p>
                                    <a className="twitter-share-button" rel="noopener noreferrer" target="_blank"
                                       href={this.state.url + this.state.tv.tv}>
                                        <img className="social shareImage" src={twitter} alt="Twitter logo"/></a>
                                    <br/>
                                    <br/>
                                    <FacebookShareButton
                                        url={'https://www.noadshere.com/tv/' + this.state.tv.tv}
                                        quote={'Check out this review!'}
                                        hashtag="#NOadsHERE"
                                    >
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <br/>
                                    <br/>
                                </div>
                            </Col>
                        </div>
                        }
                        {this.state.allTV &&
                        <div>

                            <Col className="" sm={12} lg={12}>
                                <div className="movieDiv">
                                    <Row>
                                        <Col className="" sm={4} lg={8}>
                                            <h1 className="darkGrey">TV</h1>
                                            <h4>We should probably call this Streamers! A page for new
                                                (and some old favourite) "television" shows. We'll mark any posts that
                                            have spoilers, so read with confidence!</h4>
                                            <br/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="" sm={4} lg={4}>
                                            <Form.Group className="">
                                                <Form.Control className="" type="input" id="searchId" placeHolder="Search tv shows" onChange={this.filterArray}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="allRow">
                                        {Array.from( this.state.tvEpisodes ).map((item) => (
                                                <Col className="tvCol" sm={4} lg={4}>
                                                    <div className="bgImageDiv" style={{backgroundImage: "url(" + require("../../assets/images/tv/" + item.image_1.image).default,  backgroundSize: "cover"}}>
                                                        <Card className="contentCard tv" key={item.name}>
                                                            {!item.darkTitle && <a className="cardLink" href={"/tv/" + item.tv} >
                                                                <p className="cardLinkText">{item.name}</p></a>}
                                                            {item.darkTitle && <a className="cardLink" href={"/tv/" + item.tv} >
                                                                <p className="cardLinkTextDark">{item.name}</p></a>}
                                                        </Card>
                                                    </div>
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </div>
                            </Col>
                        </div>
                        }
                    </Row>
                </Container>
                <div className="footerAbout">
                    <a className="footerLinks" href="/">home</a>
                    <a className="footerLinks" href="/tv/all">tv</a>
                    <a className="footerLinks" href="/movies/all">movies</a>
                    <a className="footerLinks" href="/recipes/all">recipes</a>
                    <a className="footerLinks" href="/about/">about</a>
                </div>
            </div>
        );
    }
}

export default withRouter(TV);

