export const tv_details = [
    {
        "name": "The Acolyte - Star Wars but Vicious (SPOILERS!)",
        "tv": "the-acolyte-star-wars-but-vicious-spoilers",
        "subtitle": "Not for the kids",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "date": "27/June/24",
        "image_4": "",
        "image_3": {image: "acolyte_004.jpg", text: "Very cool ... but very dead", alt: ""},
        "image_2": {image: "acolyte_003.jpg", text: "Master Sol", alt: ""},
        "image_5": "",
        "image_1": {image: "acolyte_002.jpg", text: "You know they're bad when the saber is red", alt: ""},
        "image_6": "",
        "paragraph_1":"MAJOR SPOILERS follow for the Acolyte, the new Star Wars tv series on Disney+," +
            " up to episode 5, so if you don't want to know, hop on the next Jedi ship out of here.",
        "paragraph_2":"We're actually going to say it again - SPOILERS! Like seriously, we're about" +
            " to discuss things that may blow your mind!",
        "paragraph_3":"Ok - we warned you! When the Acolyte started off we thought it kind of" +
            " like standard Star Wars fare - lightsabers, young Jedis, older Jedis, " +
            "wannabe Jedis, and we liked it. Set about 100 years before the original movies, " +
            "we knew we'd be meeting all new characters, and thought it pretty cool that Carrie-Anne Moss" +
            " (AKA Trinity in the Matrix) was starring as a Jedi. That was of course, until she was killed" +
            " in the first episode, followed by a couple more deaths over the next few episodes. Fair enough we thought," +
            " the show was setting out its \"we're serious like Andor\" stall early, but it also was " +
            "introducing us to new characters like Jecki, Yord and Master Sol, that we figured would " +
            "be the main characters of the show that we would know and love.",
        "paragraph_4":"How naive we were! In Episode 5 they pretty much all get butchered! Yord gets his neck snapped in a classic \"Bad guy savagely kills" +
            " a guy by twisting his head around\" move, and Jecki - the young Jedi who earlier in the episode" +
            " was kicking ass, gets brutally stabbed and dies! Like dead dead! Like us, Master Sol (Lee Jung-jae) can't believe it, exclaiming" +
            " \"she was a child\" to which our new fave Sith bad guy points out \"you brought her here\", which" +
            " to be honest, is a fair point. We've realised that this isn't a show to portray Jedis as the all powerful" +
            " and wise good guys, it's aiming for something more nuanced - and darker. "  ,
        "paragraph_5":"Where the show goes from here, we're not sure. We still have our main protagonists alive - Mae & Osha," +
            " both played by Amandla Stenberg, Qimir The Sith (Manny Jacinto) and the wise but shocked Master Sol - but" +
            " with so many of the accompanying cast now dead in a jungle, we're beginning to suspect that this isn't" +
            " a Happy Ever After series like the Mandalorian, it's going to be more of a tragedy where everyone" +
            " else will end up as Jedi ghosts aswell. However it ends up - we know one thing, this is not for kids. So, if" +
            " you're thinking that it'd be a nice family tv night, think again, as the 6 year old will be asking" +
            " what just happened to Yord's neck!",
        "paragraph_6":"One thing the blood-letting reminded us of was the last Dr. Strange movie where " +
            " the Scarlett Witch AKA Elizabeth Olson goes to town on some superheroes in an alternative universe," +
            " killing them rather more easily than usually happens in the Marvel movies. At the time we felt this" +
            " scene was a bit cheap and in bad taste - that because it was an alternative reality they could just knock off some good guys" +
            " with no actual repercussions for anyone. Likewise with the Acolyte - as the series is set so far back " +
            "from everything else, maybe they just couldn't resist eliminating folks left and right." ,
        "paragraph_7":"In summary - it's worth watching, don't get too attached to anyone.",
        "image_sources":"https://press.disneyplus.com/media-kits/star-wars-the-acolyte",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Not for the kids."
    },
    {
        "name": "Buying London Review - It's Awful",
        "tv": "buying-london-review-its-awful",
        "subtitle": "London is beautiful, the show is not",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 1,
        "wifereview": "",
        "kidsreview": "",
        "date": "27/May/24",
        "image_4": "",
        "image_3": "",
        "image_2": {image: "london3.jpg", text: "Just another pic of London for your pleasure", alt: ""},
        "image_5": "",
        "image_1": {image: "london2.jpg", text: "Not actually a photo from the show, it's just London looking lovely", alt: ""},
        "image_6": "",
        "paragraph_1":"We generally don't have bad reviews on this site, as we're so time poor that we tend" +
            " to only watch shows that we think we're going to like. Perhaps not the best approach for a review" +
            " site, but that's life! We however didn't have that problem with Buying London, a new reality" +
            " show on Netlix, all about a real estate agents who deal in \"super prime\" properties - i.e. very" +
            " expensive gaffs. How do we put this delicately? This show is absolutely awful.",
        "paragraph_2":"We started watching it as it was about selling fancy houses in London - and we just happen" +
            " to love fancy houses and London! London is a remarkable city, massive, beautiful, full of interesting houses," +
            " parks, museums, streets, people, bridges, history and so much more. It's a city you can wander around" +
            " for days, discovering new things and places, and we love visiting. We also love houses - I mean, who doesn't?" +
            " However - we didn't realise that Buying London is basically just a really bad \"reality\" show, more about" +
            " the trivial dramas between the beautiful agents in the company. We say \"reality\" in quotes, because" +
            " all the drama looks manufactured, is boring, and maybe is there to distract from the fact that they" +
            " don't seem to ever sell any houses! We gave up watching after 3 episodes, so we apologise if they" +
            " go on a selling spree in the remaining episodes.",
        "paragraph_3":"Is there anything to like about the show? London itself looks amazing - lots of impressive drone" +
            " shots - and the houses on show aswell are just gorgeous. Completely over-the-top expensive, but fabulous" +
            " to look at. By the second episode we'd started fast-forwarding through any of the \"drama\" bits - i.e." +
            " when they producers got the female agents to argue amongst themselves on camera for our benefit - to" +
            " the house bits, which is what appealed to us in the first place. Kind of like in Lord of the Rings Part 3, " +
            "we find ourselves fast-forwarding through all the Gollum bits, we just want to see Gondor.",
        "paragraph_4":"In summary - avoid. It's a show so " +
            "bad that we actually didn't even bother showing the official press images - we thought you, our" +
            " valued readers, would benefit more from some nice pics of London that we took ourselves instead.",
        "paragraph_5":"",
        "paragraph_6":"" ,
        "paragraph_7":"",
        "image_sources":"https://noadshere.com/ ",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Total waste of time."
    },
    {
        "name": "Dead Boy (Actually Grown Men) Detectives Review",
        "tv": "dead-boys-actually-grown-men-detectives-review",
        "subtitle": "It's not Sandman but still lots of fun ...",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "date": "10/May/24",
        "image_4": "",
        "image_3": {image: "deadboy_003.jpg", text: "The Grim Reaper", alt: ""},
        "image_2": {image: "deadboy_002.jpg", text: "Evil, but wonderful", alt: ""},
        "image_5": "",
        "image_1": {image: "deadboy_001.jpg", text: "Dead Actually Grown Men Detectives, innit?", alt: ""},
        "image_6": "",
        "paragraph_1":"We are massive fans of the Sandman - both the comics and last year's TV series on Netflix," +
            " so we were of course going to try out the Dead Boy Detectives, a new series set in the same" +
            " \"universe\". SPOILERS follow, so jump into your nearest magic mirror if you don't want to hang around.",
        "paragraph_2":"One thing we don't like, and maybe it's just us, is that the Dead Boy Detectives - AKA two ghosts," +
            " one (Edwin) from 1916, one (Charles) from the 1980s, are stated as being teenage boys, but are clearly being" +
            " played by men in their twenties i.e. Dead Actually Grown Men Detectives. It made one the tragic backstories a bit confusing as we were like," +
            " \"why is he in a school, he's about 27?\" and it was only in episode 2 when one of them is referred" +
            " to as a teenage boy that we twigged that they're supposed to be younger. We know this is common practice in \"teen\" shows, were the high-schoolers" +
            " are usually all in their twenties - we think it's because the producers think it would look wrong" +
            " for actual teenagers to be doing the things the characters get up to i.e. sex, drugs, violence. " +
            "We never like it though, it just seems weird to have a grown guy referred to as a teenage boy.",
        "paragraph_3":"Anyway - this is a fun show. It's not Sandman, which we think was a beautiful, thoughtful and" +
            " memorable bringing to life of the comics. The Dead Actually Grown Men Detectives is full of magic, action," +
            " fun characters and looks fantastic, but probably isn't as deep a show, if that doesn't sound" +
            " too pretentious. If you like Neil Gaiman though, you'll love this - it's wonderful mix of serious and silly. The leads are great " +
            "- George Rexstrew (Edwin), Jayden Revri (Charles, innit?) and Kassius Nelson (as Crystal) - and it is full of great characters" +
            " like Jenn Lyon as Esther, a witch, who is just eating up scenes and loving every minute of it." +
            " Crystal tells the two guys that they \"are like a dead married couple on acid\" and that's a nice summary!",
        "paragraph_4":"We do see Death (Kirby Howell-Baptiste) right at the start of episode one, and we expect we'll" +
            " meet some more Sandman alumni (we've only watched the first two episodes so far) along the way, though we reckon" +
            " we won't see the big one, Dream, himself; hopefully we're wrong! The comics are full of stories and characters" +
            " so expanding the \"universe\" makes sense in these content hungry days, and we're not complaining.",
        "paragraph_5":"",
        "paragraph_6":"In summary - lots of fun and worth a watch. Come back soon for more reviews!" ,
        "paragraph_7":"",
        "image_sources":"https://www.netflix.com/tudum/galleries/dead-boy-detectives-photos",
        "spoliers": true,
        "darkTitle": false,
        "summary": "It's not Sandman but still lots of fun ..."
    },
    {
        "name": "WTF is Happening in Sugar?!",
        "tv": "wtf-is-happening-in-sugar",
        "subtitle": "Seriously, it can't really be ...",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "date": "13/April/24",
        "image_4": "",
        "image_3": {image: "sugar_003.jpg", text: "Alien overlord? Alien middle management? Death of the Endless?!", alt: ""},
        "image_2": {image: "sugar_002.jpg", text: "A handsome devil", alt: ""},
        "image_5": "",
        "image_1": {image: "sugar_001.jpg", text: "", alt: ""},
        "image_6": "",
        "paragraph_1":"We were due to write a review of Sugar," +
            " the new Apple TV+ detective series with Colin Farrell after watching the first two episodes but" +
            " got delayed and ended up watching the third episode and .... WTF is going on?!! Major SPOILERS follow," +
            " like BIG SPOILERS so leave now if you don't want to know anything!",
        "paragraph_2":"We say spoilers, but it's more speculation as we haven't seen the full series yet, only" +
            " the three episodes released so far. So ... beware our SPECULATION!",
        "paragraph_3":"Where do we start? Maybe with our now obsolete review of the first two episodes. Sugar is " +
            "a private detective show, with Colin Farrell as John Sugar, who specialises at finding missing people." +
            " We see his skills in play in Japan where he finds a kidnapped boy, then we're off to Holywood where" +
            " he's hired by a movie mogul type, Mr. Siegel, to find his missing 25 year old granddaughter, Olivia." +
            " All perfectly normal so far - it's an Apple show so it's made well, looks great and is full of good actors." +
            " Farrell is a movie star, and looks amazing as the good hearted private eye who loves old movies and who" +
            " clearly fancies himself as a bit of noir hero himself. He's smart, speaks multiple languages, drives a cool car," +
            " wears Saville Row suits, and " +
            "can look after himself physically as we see in a couple of skirmishes. The series is good, a little bit" +
            " 'arty' as you'd expect from a prestige TV series with a big star, but it moves quickly and doesn't" +
            " get too fancy with itself.",
        "paragraph_4":"Ok so far? We were too, then we watched Episode 3 ... after which we said, and we quote: \"What the f***?\"" +
            ", then grabbed our phone and googled - we're having difficult even saying this, we googled -\n\n\"Is John Sugar an alien?\"." +
            " An ALIEN! Yes, please keep reading, we haven't lost our minds. We did some more googling and it appears from folks " +
            "who've seen and reviewed the entire series that there's a big twist in the show (we still haven't gotten around to " +
            "requesting preview access to Apple TV+ shows but will be after this!). ",
        "paragraph_5":"Why do we think aliens? We meet in earlier episodes Sugar's boss / handler (the always amazing Kirby Howell-Baptiste) who seems to be the one" +
            " who sets him up with jobs, and she's not happy that he's taken on a new missing person's case so soon after the last one." +
            " In Episode 3 however, Sugar goes to a party at her house where there are many other folks who he knows, who" +
            " all take turns talking to her in private. When it's his turn, it's for her to chastise him on his bad reporting" +
            " of ... humans, we think? There's a big black case on the table which he glances at, while asking about 'Them'. Later," +
            " she's seen opening the case and typing into what looks like an old typewriter - perchance communicating" +
            " with her alien overlords?! All sound a bit, circumstantial?" +
            " We started thinking back over what we'd seen so far - like when Sugar lifts a big guy off his feet in one scene," +
            " and then remembered all the scenes where he ... looks up at the moon! Are 'They' up there?! He also" +
            " injects himself in the neck with some drug that we presumed before was heroin or something, but now" +
            " we're wondering is it something to help his alien body function on our planet!",
        "paragraph_6":"Maybe we're getting it all wrong, and these are clues placed to confuse us. Maybe there's another" +
            " crazy explanation - like he's a clone, or a robot, or ... maybe, just once, the answer is ALIENS! "+
            " If that is the case, I think we may need to sit down somewhere and a have a quiet drink, a bit like Sugar" +
            " enjoys doing (for the \"romance of it\") and wonder what the writers were drinking when they turned" +
            " a pretty cool looking private eye show into ... what, revenge of the body snatchers? In fact, we're " +
            "off now to have that drink. Come back soon when hopefully we'll have more answers!" ,
        "paragraph_7":"",
        "image_sources":"https://www.apple.com/tv-pr/originals/sugar/episodes-images/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "We're very confused"
    },
    {
        "name": "Shōgun Review - Pure Brilliance",
        "tv": "shogun-review",
        "subtitle": "Beautiful and epic.",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "date": "16/March/24",
        "image_4": {image: "shogun_004.jpg", text: "", alt: ""},
        "image_3": {image: "shogun_002.jpg", text: "", alt: ""},
        "image_2": {image: "shogun_001.jpg", text: "", alt: ""},
        "image_5": {image: "shogun_005.jpg", text: "", alt: ""},
        "image_1": {image: "shogun_003.jpg", text: "", alt: ""},
        "image_6": "",
        "paragraph_1":"Shōgun is a beautiful, epic new series about Japanese politics in the 1600s" +
            " and is just a delight. Some SPOILERS follow so bow and take your leave if you don't want any!",
        "paragraph_2":"We'll be honest right at the start and admit that we have always had a fascination with Japan" +
            " - its culture, its food, its history - and Shōgun just gives you all of that in spades. This is an" +
            " extremely well made show, full of very detailed costumes, sets, conversations and history. It's " +
            "mostly in Japanese but in a streaming culture where we have subtitles on everything we watch anyway, it doesn't" +
            " feel strange at all. More jarring is when characters speak 'Portuguese' - but are actually speaking English." +
            " This is because the story is roughly based on a true story of William Adams (here called John Blackthorne)" +
            " as re-imagined by a 1975 novel, Shōgun, so details like Europeans speaking to the Japanese in Portuguese" +
            " are maintained, as that's how it really went down.",
        "paragraph_3":"We're not historians so we don't actually know if all the details and history on show are correct, somewhat" +
            " correct, or way off the mark. What we do know is that the show sets out its The 1600s in Japan Were Vicious stall" +
            " early, with gruesome deaths (seeing a sailor getting boiled alive), arbitrary justice (a peasant getting quickly" +
            " decapitated, for a reason we're not immediately clear on, but we think because he was in the way) and more. It's not" +
            " savagery for the sake of it though - it's all bundled up in the culture and honor-system of the the times, where" +
            " samurai have utter loyalty to their lords.",
        "paragraph_6":"" ,
        "paragraph_4":"Hiroyuki Sanada plays Lord Yoshii Toranaga, the cunning leader of one clan vying for control of Japan," +
            " and is as always a delight, playing the role of the dangerous but also clever leader perfectly. Anna Sawai (who" +
            " we also saw recently in Apple Tv+'s Godzilla tv series Monarch: Legacy of Monsters) is Lady Mariko - an intelligent" +
            " nobel who acts as a translator for the 'barbarian' Blackthorne, who is a fascination to Toranaga due to his" +
            " knowledge of both sailing and the wider world. One scene where Toranaga asks Blackthorne to draw him a map" +
            " of the world highlights how isolated Japan is from the rest of the world.",
        "paragraph_5":"Shōgun is not full of massive battles - it is more about the politics of the era, the culture, " +
            "and how an European sailor tries to understand and survive there. There are moments of violence, like " +
            "a castle maid who turns out to be an assassin from a secret sect where assassins train all their lives" +
            " for one mission, along with brutal reminders of the culture's rules - when a baby is put to death as" +
            " his father disrespected Lord Toranaga by speaking up against his enemies out of turn.",
        "paragraph_7":"Summary: A beautiful, epic, intelligent, savage show - sit back and enjoy TV at its finest.",
        "image_sources":"https://press.disneyplus.com/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Epic brilliance."
    },
    {
        "name": "Constellation Review - Mindbending Sci-Fi",
        "tv": "constellation-review",
        "subtitle": "Maybe watch with lights on.",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "date": "04/March/24",
        "image_4": "",
        "image_3": {image: "Constellation_002.jpg", text: "", alt: ""},
        "image_2": {image: "Constellation_003.jpg", text: "", alt: ""},
        "image_5": "",
        "image_1": {image: "Constellation_001.jpg", text: "", alt: ""},
        "image_6": "",
        "paragraph_1":"We've seen the first four episodes of Constellation, a new sci-fi mindbender on Apple TV+ and" +
            " we quite like it. Some mild SPOILERS follow so jump in your Soyuz escape pod now if you don't want to know" +
            " anything about it!",
        "paragraph_2":"Noomi Rapace (Girl with the Dragon Tattoo, Prometheus) stars as Jo Ericsson, a Swedish astronaut on " +
            " the International Space Station (ISS) where strange things are happening. Strange things which start to also happen" +
            " also when she's back on Earth - like her car being a different colour than she remembers, and not remembering" +
            " that she had an affair with her boss in the European Space Agency. The first couple of episodes feel kind of " +
            "like a horror movie - lots of tension, darkness, dead bodies that we're convinced are going to come alive" +
            " suddenly, and just a general overall sense of dread. The space station is an ideal place for this - where" +
            " better to feel isolated and terrified than alone miles above the Earth, in darkness, just you and " +
            "noises coming from where there should be no noises! ",
        "paragraph_3":"The series then, surprisingly to us, kind of reveals in enough clues what's more or less going on" +
            " (we won't spoil it) so it becomes a bit less mysterious, but it doesn't really matter as there's" +
            " still plenty to chew on. Her daughter also seems to experiencing the strange effects, Jonathan Banks " +
            "(Mike Ehrmantraut in Breaking Bad) plays a very p***ed off old astronaut / scientist who definitely knows more" +
            " than he's letting on, James D'Arcy plays the husband who doesn't seem necessarily too happy that she's home," +
            " there's some clear Big Secret Cover Up vibes going on and more!",
        "paragraph_5":"This is a very well" +
            " made show, with great actors given great material to work with. Rapace is expectedly fantastic - both in space " +
            " and back on solid ground, and we definitely find ourselves drawn to her and her story. You may also like the" +
            " frights and jumps. While our brain is telling us 'it's not a ghost, it's something to do with the quantum" +
            " experiment going on', when she goes into the kitchen of the log cabin in the snow to heat up some more water for her daughter" +
            " who's having a bath and then sees ... well, we don't want to spoil" +
            " it!" ,
        "paragraph_4":"",
        "paragraph_6":"In the streaming world where we spend a lot of time trying to decide what to watch, this is a nice" +
            " new option that we're happy to recommend. Come back soon for more reviews where we'll see if we've guessed" +
            " right on what's going on!",
        "paragraph_7":"",
        "image_sources":"https://press.disneyplus.com/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Maybe watch with lights on."
    },
    {
        "name": "Echo Review - Not Your Usual Hero",
        "tv": "echo-review-not-your-usual-hero",
        "subtitle": "A strong Marvel outing",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "date": "19/Jan/24",
        "image_4": {image: "echo4.jpg", text: "Just a very cool poster", alt: ""},
        "image_3": {image: "echo3.jpg", text: "Where's the wine gone?", alt: ""},
        "image_2": {image: "echo2.jpg", text: "kickass", alt: ""},
        "image_5": "",
        "image_1": {image: "echo1.jpg", text: "Badass", alt: ""},
        "image_6": "",
        "paragraph_1":"Echo is a new Marvel tv series on Disney+ / Hulu and it is a delight. Mild spoilers follow so " +
            "jump now if you want to go in clean!",
        "paragraph_2":"Part of the MCU, Echo's stars Alaqua Cox, a deaf, amputee, Native-American who plays" +
            " the absolute badass hero/villain Maya Lopez, who is also Native American, deaf and an amputee, in probably" +
            " one of the best castings of all time. Cox is amazing, just a presence on screen that you can't take your" +
            " eyes off, easily portraying the rage and fury her character has, along with so much more. We binged" +
            " this show burning through the series' 5 episodes in barely one day - mainly so we could see more" +
            " of the main character and see what happens to her. If we have any complaints about the show it's that" +
            " it is so short - just 5 episodes, each of which is about 40 mins or so, which includes the as-usual way too long MCU Disney+ credits.",
        "paragraph_3":"The unique nature of our hero makes for compelling viewing - as she makes her way through the story," +
            " you just wonder how things will play out, as we see her trying to figure things out in a world she can't hear." +
            " There are long vicious fights (this is much more like the Netflix Marvel shows like Daredevil), emotional" +
            " reunions with her family, tension filled scenes with the Big Bad, Wilton Fisk - and in them all you can't" +
            " take your eyes of Cox, who emotes so much with just her expressions. We love heroes like Thor and Captain America," +
            " but there's much more of interest on show here. She is also an anti-hero - she's basically starts the show as a villain" +
            " wanting to become the 'Queen' of crime, so we also have to ask ourselves should we really be rooting for her or not?" +
            " As she comes back to her old home town, and some folks warn her not to bring her New York problems to them, we" +
            " kind of agree with them! But over the 5 episodes we definitely end up on her side, in part of course due to " +
            "the obligatory Tragic Back Story, but mainly as we just got caught up following her quiet fury to the end.",
        "paragraph_5":"We have to mention Vincent D'Onofiro, who plays Wilton Fisk AKA Kingpin - reprising his role" +
            " from the Daredevil series, and the recent Hawkeye. He is easily our favourite villain in the MCU, and while" +
            " the current short episode format doesn't leave much room for major character development like he had before," +
            " he still just dominates any scene he is in. Both physically dangerous and utterly ruthless, he's the bad guy" +
            " the MCU needs, not the multiverse villains they have been pushing. We don't need inter-dimensional masterminds, " +
            "we need a complex crime boss who can eat dinner happily while his own interpreter is being killed so she won't" +
            " give any secrets away.",
        "paragraph_4":"",
        "paragraph_6":"There's plenty more to talk about but we don't want to spoil too much, so we'll leave the review here!" +
            " Please share this post if you liked it!",
        "paragraph_7":"",
        "image_sources":"https://press.disneyplus.com/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Not your usual hero"
    },
    {
        "name": "Netflix VS Mr. Beast Squid Game",
        "tv": "netflix-vs-mr-beast-squid-game",
        "subtitle": "Which is worth your precious time?",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "date": "30/Dec/23",
        "image_4": {image: "mrbeast_squid1.jpg", text: "Real people, real falls", alt: ""},
        "image_3": {image: "netflix_squid1.jpg", text: "Scary in all three versions", alt: ""},
        "image_2": {image: "netflix_squid2.jpg", text: "At least no-one died", alt: ""},
        "image_5": "",
        "image_1": {image: "squidbeast.jpg", text: "Image source: Netflix & YouTube", alt: ""},
        "image_6": "",
        "paragraph_1":"One of my favourite Steve Jobs quotes is 'Time is our most precious resource' and in this" +
            " multi-streamer world I need to use my sparse tv viewing time accordingly. I've never watched " +
            " the original Squid Game series, mainly as I didn't like the idea of people being killed for entertainment" +
            " in such a mean way. I did however watch Mr. Beast's version, and loved it, and have just watched the " +
            " first couple of episodes of Netflix's Squid Game 'The Challenge' - basically both are real life versions" +
            " of the series, minus any real deaths!",
        "paragraph_2":"So, which is better - Mr. Beast's or Netflix's? It probably depends on what you're looking for." +
            " As I started the first episode of the Netflix series, I turned to my better half and said 'I hope" +
            " they don't drag everything out to fill up the episodes' - and you betcha do they drag out the episodes." +
            " In true reality show style, the action is interrupted by one on ones with contestants as they talk" +
            " about their motivations, their strategies etc. I understand why Netflix chose this approach - it gives" +
            " them more content for more episodes and theoretically it helps the viewer become more invested in the" +
            " folks undergoing the challenges. To be honest, I'd just rather watch the action, I've little to no interest" +
            " in the machinations and thoughts of people who may or may not have been chosen as they are 'interesting'.",
        "paragraph_3":"Mr. Beast however is known for his quick videos, showing a tonne of content in only 10 to 15 minutes. " +
            " This episode is one of his longest, at almost 26 minutes, and it's clear why, given the scope and scale" +
            " of what is on show. He mentions during the video that it all cost about 3.5 million dollars; I'm presuming" +
            " the Netflix version cost much more, but both look fantastic. From the iconic Red Light Green Light game, to the glass" +
            " bridge, both versions look amazing. We did read however on Netflix's Tudum blog that some things on their" +
            " version are faked - like when contestants fall from the bridge, Netflix uses stuntpeople. So I'm not so sure" +
            " we like that; on Mr. Beast's version it looks like folks are falling for real!",
        "paragraph_5":"I prefer Mr. Beast's version, it is relatively short but still packs in a lot of action, and has" +
            " none of that reality show focus on the players that is just boring at this stage. I of course don't like" +
            " that the Mr. Beast video is interrupted by several ads but that's more general hating-the-ads view we have about" +
            " any ads! If you do like reality shows though, you'll like the Netflix version. It's obviously of high quality," +
            " and does have lots of tension and drama and contestants that you'll love and/or hate!",
        "paragraph_4":"",
        "paragraph_6":"So in summary - both are good, depending on what you like from your shows. Feel free to let us know" +
            " what you think by sharing this post on your social media platform of choice!",
        "paragraph_7":"",
        "image_sources":"https://www.netflix.com/tudum/articles/squid-game-the-challenge-glass-bridge",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Which is worth watching?"
    },
    {
        "name": "Reacher Series 2 - Ep 1 & 2 Review",
        "tv": "reacher-series-2-ep-1-and-2-review",
        "subtitle": "You don't mess with the Special Investigators",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "date": "16/Dec/23",
        "image_4": {image: "reacher_s2_001.jpg", text: "", alt: ""},
        "image_3": {image: "reacher_s2_003.jpg", text: "", alt: ""},
        "image_2": {image: "reacher_s2_004.jpg", text: "Big, and he can read", alt: ""},
        "image_5": "",
        "image_1": {image: "reacher_s2_002.jpg", text: "", alt: ""},
        "image_6": "",
        "paragraph_1":"We've just watched the first two episodes of series 2 of Reacher, on Prime Video, and it's as good" +
            " as we hoped. Very mild SPOILERS follow so abandon ship now if you don't want to know!",
        "paragraph_2":"If you haven't seen series 1, stop reading this review and go and watch it. Although a well known" +
            " book series with 2 movies with Tom Cruise, Reacher was a surprise when it came out - a smart, well made" +
            " show with a revelation as the main star - Alan Ritchson, a mountain of a man who managed to have some of the" +
            " best on-screen fights we'd seen in a while along with actually being an interesting and intelligent " +
            " character.",
        "paragraph_3":"What we've seen of Series 2 is more of the same - a fight scene taking out a carjacker that" +
            " already has us hitting the repeat button on YouTube, and more of Reacher being both a walking" +
            " weapon and also giving us a show where we're genuinely concerned on the wellbeing of the characters." +
            " We're guessing of course that Reacher himself will get through the series relatively unscathed" +
            " but we're also guessing that nobody else is safe, given the first two episodes' body count.  ",
        "paragraph_5":"This concern we have over Reacher's new (to us, not to him) comrades has already" +
            " allayed any doubts about whether the show could replace the first series' other characters. As " +
            "Reacher wanders around America, he leaves behind friends - which means that (most) of the folks we met in " +
            "the first series aren't returning. The new additions however are now firmly in the We Hope They Don't Get Killed" +
            " category but like we said, we have our doubts, especially as Robert Patrick AKA the former Terminator is" +
            " the main bad guy - complete with Easter Egg joke about Sarah Connor! If it turns out he's actually" +
            " still a Terminator and he works for Cyberdyne Systems we'll be both shocked and delighted!",
        "paragraph_4":"",
        "paragraph_6":"So in summary, go watch it! It's a quality show, with top class action and actors & characters" +
            " worth caring about. Come back soon for more reviews as we watch more episodes!",
        "paragraph_7":"",
        "image_sources":"https://press.amazonstudios.com/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "As good as we hoped."
    },
    {
        "name": "Culprits Review",
        "tv": "culprits-review",
        "subtitle": "You reap what you sow",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "date": "07/Nov/23",
        "image_4": {image: "culprits_003.jpg", text: "Niamh Algar - cooler than you or me", alt: ""},
        "image_3": {image: "culprits_4.jpg", text: "Total p****", alt: "Lady in dress in medieval tavern, smilingx"},
        "image_2": {image: "culprits_001.jpg", text: "Smile!", alt: ""},
        "image_5": "",
        "image_1": {image: "culprits_5.jpg", text: "It's a bistro, not a bar", alt: ""},
        "image_6": "",
        "paragraph_1":"In school, we had to study the Mayor of Casterbridge by Thomas Hardy, whose main theme is You Reap What You Sow" +
            " - there's no escaping your past misdeeds, they'll catch up to you eventually. We've seen the first six episodes of Culprits, " +
            " a new Disney+ show, and it is soaked in the same concept - bad deeds, and what happens later. ",
        "paragraph_2":"Culprits is about a heist carried out by a disparate gang a few years " +
            "ago, and the action flits between then, and now. The main character is Joe, living a seeming" +
            " idyllic life with his fiancé Jules and Jules' kids in America. Joe, played by Nathan Stewart-Jarrett, is a black gay man" +
            " - unusual for the main star in a major show - but three years ago he was the gang member known as Muscle who" +
            " \"f***ed with some very bad people\" by robbing their vault of a lot of cash. The show is about that heist" +
            " and the consequences - when years later someone starts killing the gang, one by one." ,
        "paragraph_3":"The main strength of Culprits is the characters and the great actors involved - from 'Officer' (Kirby Howell-Baptiste)," +
            " to 'Specialist AKA Pyscho' (Niamh Algar) to 'Brain' (Gemma Atherton) this is a show full of interesting characters" +
            " and very strong acting. Nathan Stewart-Jarrett is a mixture of violence and vulnerability, and manages to " +
            "remain sympathetic even though he does do 'bad things'. 'Devil' is one of the villains (though perhaps all of the" +
            " heist gang are villians?!), played by Ned Dennehy who folks may recognise in a similar role in the recent" +
            " sci-fi show The Peripheral. In that show, and this, it shows what a quality actor can bring to a role," +
            " he is genuinely menacing as a slightly deranged hitman.",
        "paragraph_5":"Likewise, another Irish actor Niamh Algar is just mesmerising as the assassin Pyscho, a role" +
            " that traditionally may have been portrayed by a man, but here she revels in the role as the ultra deadly killer" +
            " who's quite fond of the color white! Kirby Howell-Baptiste plays Officer, who's speciality is that she's" +
            " an incredible talker and liar, and again makes us think - is she good or bad? You may recognise her" +
            " as Death from The Sandman series earlier this year, we were delighted to see her appear again in this.",
        "paragraph_4":"",
        "paragraph_6":"If there's one complaint we have with the show is the Now and Then approach to the storytelling, " +
            "where we move between the heist and the events that led to it, and the present day events. We think it may" +
            " have worked better if shown more linearly, but one can understand the idea of dragging out the reveals" +
            " of what actually happened several years ago, to add some mystery to the show.",
        "paragraph_7":"In summary - this is a good show, worth your valuable watching time - with great story, action and acting.",
        "image_sources":"https://press.disney.co.uk/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "You reap what you sow."
    },
    {
        "name": "Wheel of Time Series 2 Review ",
        "tv": "wheel-of-time-series-2-review",
        "subtitle": "Deeper, darker, better",
        "date": "20/Oct/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_3": {image: "wot_ishamael.jpg", text: "You know I'm evil because I'm whittling", alt: ""},
        "image_1": {image: "wot_lanfear.jpg", text: "Let's be honest, she's our real hero", alt: "Lady in dress in medieval tavern, smilingx"},
        "image_2": {image: "wot_egwene.jpg", text: "I will kill you", alt: ""},
        "image_5": "",
        "image_4": {image: "wot_dain.jpg", text: "Nice axe", alt: ""},
        "image_6": "",
        "paragraph_1":"Series 2 of the Wheel of Time is bigger, darker and better than Series 1. Major SPOILERS! follow" +
            " so if you haven't seen the latest series, I'd suggest you leave now!",
        "paragraph_2":"The Wheel of Time book series is huge, in terms of numbers of books, numbers of characters, plot-lines," +
            " and just the overall worldbuilding involved. Series 1 did introduce a lot of details, but we felt that there was a lot" +
            " not mentioned - like enough about the Forsaken AKA the Chosen, the immortal minions of the Dark One, imprisoned" +
            " for thousands of years, but soon to wreak havoc on the world again. Boy does Series 2 remedy that! The storylines" +
            " this year seem more developed, and wider in scope, maybe because our heroes are separated more, each following" +
            " their own adventures." ,
        "paragraph_3":"A complaint we had about the first series was that it seemed a bit too clean - it didn't feel grimy" +
            " like Game of Thrones, it felt like a TV series. This has definitely improved, whether on purpose or just in " +
            "our own heads, as we get more invested with the characters. It still has what we call the What Is Outside Gondor problem" +
            " - in Lord of the Rings, you have these cities like Gondor that don't seem to have anything outside them - like" +
            " working farms, industry etc. Just big cities with walls and nothing outside. WoT is a bit like that, but we don't" +
            " think there's any real answer to it, unless they want to spend even more money on CGI.",
        "paragraph_5":"We've read that there is about 10 series planned, which makes sense given the number of books, and hopefully" +
            " folks who haven't read the books are beginning to understand the scale and complexity of the story. The Children" +
            " of the Light (with Dain with a really cool axe), the Aiel with their even cooler hand talking, the Seanchan, " +
            "and so much more. Mat so far has been a bit of a peripheral character, but is redeemed by the end and seemingly" +
            " he 'remembers'. In the books, he was easily our favourite character, for many reasons, but including that " +
            "he could remember all his past lives on the Wheel - where he was always a general or soldier, so" +
            " he then an incredible military mind with memories of countless battles. It's the greater depth to the characters" +
            " that began to grow in this series, and there's so much more to come.",
        "paragraph_4":"That minor quibble aside, this series has more depth to it, and it is darker. The Forsaken are reviled" +
            " for a reason - they are evil, and bring a level of danger to the show which was needed. Likewise, our heroes" +
            " are put through the mill - Egwene is captured by the simply easy-to-hate Seanchan, Rand gets a tight haircut" +
            " so we definitely know he's all about business now, and we meet the Black Ajah! AKA Aes Sedai who secretly" +
            " work for the Dark One!",
        "paragraph_6":"In summary, just a fantastic second series, an easy 5 stars from us, and we can't wait for series 3.",
        "paragraph_10":"",
        "image_sources":"https://press.amazonstudios.com",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Just a fantastic second series."
    },
    {
        "name": "Ahsoka Review - Wonderful Old School Star Wars ",
        "tv": "ahsoka-review-wonderful-old-school-star-wars",
        "subtitle": "Lightsabers, Villains and Jedis!",
        "date": "26/Aug/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_3": {image: "ahsoka_002.jpeg", text: "Master and Padawan", alt: ""},
        "image_1": {image: "ahsoka_001.jpeg", text: "", alt: "Jedi with lightsaber"},
        "image_2": {image: "ahsoka_003.jpeg", text: "It's not Star Wars without a droid", alt: ""},
        "image_5": "",
        "image_4": {image: "ahsoka_004.jpeg", text: "Cooler than me or you", alt: ""},
        "image_6": "",
        "paragraph_1":"Mild SPOILERS follow so leave now if you haven't seen the first two episodes! The late Ray Stephenson" +
            " plays a kind of 'Dark' Jedi in Ahsoka, the new Star Wars show on Disney+ and he's wonderful in a way that" +
            " nicely explains the show so far. This isn't Andor, the I-can't-believe-this-show-is-so-good Star Wars, or" +
            " even the it's-really-a-space-western Mandalorian, Ahsoka reminds us of old school Star Wars - Jedis, lightsabers" +
            " and villains, who also have lightsabers! Stephenson is a classic Darth Vader style bad guy - he's big," +
            " dressed in black, and quites like using the Force to show everyone he's the meanest guy in the room.",
        "paragraph_2":"Kids don't play with blasters when they play Star Wars, they play with lightsabers, and for us, " +
            "that's always been the best part of Star Wars; these amazing Jedis and Sith who can use the Force and also have the coolest" +
            " weapon imaginable. In the Force Awakens, when Rey stretches out her hand and Luke's old lightsaber flies" +
            " across into her hand, it just gave us the chills! Ahsoka is giving us the same feelings - the same sense of awe and fun" +
            " from watching these space warriors battle it out, lightsaber style!" ,
        "paragraph_3":"It's not just the Jedis, it's the X-wings, the ships leaving the atmosphere, the droids, everything " +
            " about it we're enjoying. We've never watched the animated Star Wars series, so we don't know the characters we're meeting" +
            " or know their backgrounds, but it doesn't matter, you don't need to know all the canon to enjoy this." +
            " We can see why people love the characters so much from the animated series, two episodes in we're already" +
            " invested in Ahsoka and her former apprentice Sabine, and we're aware of complicated shared history between the two." +
            " And when we found out Sabine's a Mandalorian! Consider us sold. Whether the OG Mandalorian Pedro Pascal will" +
            " show up we don't know, but we can imagine his similar excitement at meeting a comrade who's also one of " +
            " those sorcerers he's heard so much about! ",
        "paragraph_5":"In summary, we love what we've seen so far (we've only seen the first two episodes) and look forward" +
            " to next Wednesday to see the next one. Come back here for more reviews, and let's be honest, more total fanboying!",
        "paragraph_4":"",
        "paragraph_10":"",
        "image_sources":"https://press.disney.co.uk/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Lightsabers, Villains and Jedis!"
    },
    {
        "name": "Still The Only Show My Teenagers Want to Watch With Me ",
        "tv": "still-the-only-show-my-teenagers-want-to-watch-with-me",
        "subtitle": "Only Murders in The Building Series 3",
        "date": "08/Aug/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_3": {image: "omitb003.jpg", text: "Mama Mia", alt: ""},
        "image_1": {image: "omitb001.jpg", text: "OMG", alt: "Three people with their mouths open"},
        "image_2": {image: "omitb004.jpg", text: "No, I didn’t steal anything! I was returning something I stole.", alt: ""},
        "image_5": "",
        "image_4": {image: "omitb002.jpg", text: "WTF", alt: ""},
        "image_6": "",
        "paragraph_1":"We've just watched the first two episodes of Series 3 of Only Murders in the Building and it didn't" +
            " disappoint. Mild SPOILERS follow but nothing too bad.",
        "paragraph_2":"OMITB is pretty much the only show that my teenagers want to watch with me - usually they pause" +
            " whatever they're watching if I appear and have no interest in most of the things I watch. Take Selena Gomez however" +
            " and add her to Martin Short and Steve Martin and you've a Parent & Teenager mix for the gods! It helps" +
            " that the show is funny, with a healthy does of whodunnit, with episodes that are just short enough to " +
            "keep holding interest." ,
        "paragraph_3":"One of the teenagers says that Gomez is probably one of her top 5 people, \"like in the world\", and for some" +
            " context, I don't think I've even made that list. Martin Short is also a favourite, his over the top (when is he never" +
            " over the top?) theatre director just as good as in previous series. This year we actually get to see " +
            " him direct, and it's still a nuanced performance like with most of the characters theoughout the run of " +
            " the show. Yes, he can be flamboyant and silly, but also can portray the quiet serious moments just perfectly.",
        "paragraph_4":"Paul Rudd AKA Antman AKA Girl Cop's Friend (it'll make sense when you see it) is a new addition" +
            " to the series, whom the teenagers know from Marvel, Meryl Streep who they know from Mama Mia is of course" +
            " amazing, and you should have heard the yelp of recognition when Jesse Williams AKA Avery from Greys Anatomy pops up." +
            " So we've got a stacked cast of people they know, and ANYONE could be the murderer! ",
        "paragraph_5":"Without spoiling anything, there's obviously been a murder and at this stage there's a tonne of suspects, given" +
            " how hard they're working to show what a total douchebag the victim was. " +
            "Just as the three amigos seem happy at getting to do another podcast, we as viewers get to share that feeling." +
            " This is a great, different, show, one that on paper maybe doesn't work - but each week for 35 mins or so it's" +
            " a little slice of TV perfection. The cast is amazing, the acting great, the jokes funny, the plot intriguing" +
            " and it just look tremendous aswell. When we grow up, we want an apartment like Oliver's. The Age of Streamers" +
            " means there's more content to watch than ever before, but it seems that the gems are getting rarer, so when" +
            " a new series of a show like this comes up, it's worth finding some time with the family to sit down and watch it.",
        "paragraph_6":"Come back soon for more OMITB reviews and speculation! Our early money is on Avery!",
        "paragraph_10":"",
        "image_sources":"https://press.disney.co.uk/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Only Murders in The Building Series 3"
    },
    {
        "name": "Tex Mex Motors Review",
        "tv": "tex-mex-motors-review",
        "subtitle": "Cool cars, in a slightly strange show",
        "date": "03/Aug/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 3,
        "wifereview": "",
        "kidsreview": "",
        "image_3": {image: "texmex003.jpg", text: "", alt: ""},
        "image_1": {image: "texmex002.jpg", text: "", alt: ""},
        "image_2": {image: "texmex001.jpg", text: "", alt: ""},
        "image_5": "",
        "image_4": "",
        "image_6": "",
        "paragraph_1":"Tex Mex Motors is the latest car restoring show on Netflix, and after watching other similar series" +
            " - Rust Valley Restorers, From Rust to Riches - we were a little dubious about another Scrappy-Crew-Needs-A-Big-Buyer-For-This-Car" +
            " show but we've just binged it and we kind of liked it.",
        "paragraph_2":"This show's gimmick is that they buy rundown classic cars in Mexico, then bring them back" +
            " over the border to El Paso in Texas to restore them. It's not fully explained how the 'shop' got together" +
            " - they say they've come together for the summer to see if this business model works with the aim" +
            " of making $250,000 in profit. We don't know the background to the show, so we suspect it was all put" +
            " together by a TV company and that there's a lot going on off-camera that we don't see. That being said," +
            " they all seem like real mechanics / car dealers so when they are wheeler-dealering or cutting up cars, " +
            "it seems legit." ,
        "paragraph_3":"We usually have these 'is this show real?' questions when watching series like this but to be honest" +
            " we don't really care. What makes us binge is the cars themselves - seeing a classic be transformed into" +
            " something beautiful, with everything from Mustangs, to Oldsmobiles to Broncos. We do wonder on the quality" +
            " of the craftsmanship - and whether we'd want to part with large amounts of cash for something built quickly" +
            " by a random shop with no name and that may not be in existence once the show hits Netflix. Maybe we're " +
            "wrong and there's warranties and the like, but again, we're digressing into reality here - who cares, " +
            "let's just enjoy the crew referring to every idea as BADASS and saying 'let's get this over to paint'!",
        "paragraph_4":"One slightly offputting note is the two 'scouts' / car-dealers in the crew - Scooter and Rabbit." +
            " Scooter is of Mexican descent, Rabbit is a white guy, and when they visit Mexico looking for cars, the dynamic" +
            " shown on screen is very much 'Scooter as the slight buffoon, Rabbit as the cranky but wiser white guy'. " +
            "We read that Scooter is one of the show's creators, so we suspect it's all a bit of an act, but it just seemed" +
            " a bit odd. Mexico is also not exactly shown in the best light, everywhere they go is kind of rundown" +
            " and folks are willing to sell their cars for bunches of hundred dollar bills. It's not all dodgy diversity, Jaime is the team's female engine specialist and clearly" +
            " knows what she's doing.",
        "paragraph_5":"So all in all, it's a bit of a strange show, one that we're not completely convinced is" +
            " all that authentic, but we still enjoyed it and loved seeing the cars being brought back to life.",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://www.netflix.com/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Cool cars, in a slightly strange show"
    },
    {
        "name": "The Walking Dead: Dead City Episode 1 Review (SPOILERS!)",
        "tv": "walking-dead-dead-city-episode-1-review",
        "subtitle": "Dark and full of misery, we love it",
        "date": "11/July/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "image_3": {image: "twddc001.jpg", text: "Cooler than you or me", alt: ""},
        "image_1": {image: "twddc002.jpg", text: "Bad Cop Worse Cop", alt: ""},
        "image_4": {image: "twddc003.jpg", text: "Even Worse Cop", alt: ""},
        "image_5": "",
        "image_2": {image: "twddc004.jpg", text: "Great to see pubs survied the zombie apocalypse", alt: ""},
        "image_6": "",
        "paragraph_1":"The Walking Dead: Dead City isn't available to stream in Ireland yet but thankfully AMC have made" +
            " the first episode available on YouTube and we enjoyed every minute of it. SPOILERS follow so check out now if " +
            "  don't want to know much, but let's be honest, if you've watched as much Walking Dead as we have, the actual" +
            " plot and twists aren't really all that important.",
        "paragraph_2":"There were 11 series of the Walking Dead, 8 series of Fear the Walking Dead and a couple of other " +
            " mini-shows, so we're fully aware what happens in these shows, in this universe. A devastated world, zombies (though never called zombies!)" +
            ", people much worse than zombies, and a ragtag bunch of decent folks trying to survive against all odds." +
            " There's also great characters, truly horrible villains, and plenty of tension and dramatic situations " +
            " that manage to keep us watching even though we've kind of seen it all before. Dead City (I can't keep writing" +
            " the full title!) is like slipping on a pair of comfortable slippers, where two characters we know and love/hate" +
            " - Maggie (always a hero) and Negan (once a total bad guy now a kind of anti-hero) - face lots of zombies," +
            " another Big Bad Guy and things just generally being all round just awful." ,
        "paragraph_3":"We have to admit that we kind of got Non-Avenger Marvel Movie vibes when Maggie goes looking for help" +
            " from Negan - it's a bit like watching a Spiderman movie and wondering why he doesn't just give Thor, Captain America" +
            " and a few of the others a call and get everything sorted before Aunt May knows he's missing. So it's just Maggie" +
            " and Negan, going after 'The Croat', a former crew member of Negan's in Manhattan AKA the Dead City. We don't mind" +
            " though, it's better than there being no more Walking Dead shows to watch.",
        "paragraph_4":"",
        "paragraph_5":"We're not entirely sure of the geography, politics or even timeline of this new show. Negan is being" +
            " hunted by 'The Marshalls', fronted by Perlie Armstrong (Gaius Charles), a kind of cowboy-styled lawman who obviously" +
            " sees himself as a bit of a Judge Dredd Judge Jury and Executioner type, and who hails from 'New Babylon'. We've no" +
            " idea where that is, but who really cares, he has a cool hat and we suspect we'll see much more of him along the way.",
        "paragraph_6":"All in all, a promising start to the new show, we just now have to figure out how to watch " +
            " the latest episodes from Ireland! One minor complaint - yes it's dark in tone, which we like, but it's actually " +
            " a bit too dark, as in, can we have a bit more action in the daytime please!",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://www.amcnetworks.com/press-releases/amc-releases-trailer-and-key-art-for-the-walking-dead-dead-city/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Great to be back in this dark dangerous world."
    },
    {
        "name": "Secret Invasion Is What Marvel Needed, A Return of Quality and Story",
        "tv": "secret-invasion-is-what-marvel-needed",
        "subtitle": "Old Man Fury is In, Even When's He's Out",
        "date": "14/June/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "secret_invasion_002.jpg", text: "Emelia Clark as G'iah", alt: ""},
        "image_3": {image: "secret_invasion_003.jpg", text: "Olivia Colman as Sonya Falsworth", alt: ""},
        "image_1": {image: "secret_invasion_001.jpg", text: "", alt: ""},
        "image_5": "",
        "image_4": {image: "secret_invasion_004.jpg", text: "Skrull!", alt: ""},
        "image_6": "",
        "paragraph_1":"We've just watched the first two episodes of Disney+'s new Marvel show, Secret Invasion, starring" +
            " Samuel L. Jackson in a welcome return as Nick Fury. This show is what we, and Marvel, needed - a return to quality," +
            " with no reliance on over the top CGI, and with drama, tension and story at its heart. We won't spoil anything" +
            " major so feel safe to keep reading - we will say that the premise is known from the trailers, alien Skrulls who" +
            " can make themselves look like any human are, well, invading secretly! ",
        "paragraph_2":"The first thing to say is that we think Episode 2 is much better than Episode 1, which we felt was a bit" +
            " disjointed and rushed, but we can understand that sometimes it's not so easy to start these shows off. Andor is " +
            " an example of this, the widely praised Star Wars show with Diego Luna that we thought also had a few slow episodes" +
            " before its true difference and quality began to ramp up. Episode 2 of Secret Invasion had us enthralled right the" +
            " way through, as the scale of the problems facing Fury become apparent, so if you watch Episode 1 and aren't so sure, " +
            " trust us and keep the faith." ,
        "paragraph_4":"We say 'seemingly' beat down as we've only seen the first two episodes made available for reviewers, and" +
            " as Maria Hill (Cobie Smulders) says, the Fury we know used to be always three steps ahead. Whether that's true" +
            " or not, we'll likely find out over the rest of the series; whether he has been planning and scheming all along" +
            " or if is he really struggling to handle age and the threat posed by the secret invasion.",
        "paragraph_3":"We don't think we've ever seen Samuel L. Jackson in a role we didn't like, and he's always been" +
            " great as Nick Fury. Here he's Old Man Fury, a limping, seemingly beaten down version of his former self," +
            " who multiple characters refer to as not being same since the events of The Blip. Note for readers" +
            " - you could probably enjoy this series if you know nothing about the rest of the Marvel shows and movies, " +
            " but we'd highly recommend either catching up, or doing some Googling to read up on what has gone before!",
        "paragraph_5":"In our review of Andor last year we mentioned the quality of actors on show, and Secret Invasion" +
            " is more of the same. Olivia Colman (The Crown, Broadchurch) is a delight as MI6's Sonya Falsworth, smiling" +
            " serenely as she tortures, Game of Thrones' Emilia Clark is just her usual great actress self as Skrull G'iah," +
            " Ben Menelsohn is back as Talos, Martin Freeman pops up again, Don Cheadle as Avenger Rhodey AKA War Machine is brilliant" +
            " as ever ... the list goes on. Kingsley Ben-Adir plays Gravik, the main Skrull antagonist with a mixture of" +
            " fury and disdain for his human enemies. This is a serious show, with serious actors brought in to give " +
            "it the level of quality it needs.",
        "paragraph_7":"We don't want to say much else in case we spoil anything, but once the show has been out a week or two" +
            " we'll return with more detailed speculation on what's going on! For now, know that so far it's had a great start," +
            " with tension and surprises, and the basic premise that anyone can be secretly an alien Skrull will probably " +
            " mean many more surprises!",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://press.disney.co.uk/gallery/secret-invasion-logo-and-key-art",
        "spoliers": true,
        "darkTitle": true,
        "summary": "A slow episode 1, followed by an incredible episode 2."
    },
    {
        "name": "Mandalorian Series 3 Episodes 1 to 7 Review (SPOILERS!)",
        "tv": "mandalorian-series-3-ep-1-to-7-review",
        "subtitle": "Still the best Star Wars",
        "date": "17/Apr/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "bo-katan-001.jpg", text: "Bo-Katen Kryze", alt: ""},
        "image_3": {image: "mando-001.jpg", text: "Mando, Mando and Mando", alt: ""},
        "image_1": {image: "mando_ship.jpg", text: "Just two guys hanging out", alt: ""},
        "image_5": "",
        "image_4": "",
        "image_6": "",
        "paragraph_1":"There's only one episode left in Series 3 of the Mandalorian, so we thought we better get a review up" +
            " before it's too late! Major SPOILERS! follow so if you're not up to date, you proceed at your own peril!",
        "paragraph_2":"Series 3 of the Mandalorian has continued the adventures of our favourite helmet wearing space cowboy" +
            " and his side-kick / kid Grogu AKA Baby Yoda in, first, his quest for redemption after removing his helmet in the" +
            " last series, and then helping Bo-Katen Kryze in her quest to regain Mandalore, the ruined planet of the " +
            "Mandalorians. There has always been a kind of over-arching story to the Mandalorian, but very much with a light" +
            " touch. This series however feels like it's more important to the story - as pieces from earlier episodes like" +
            " 'The Moff' - an Imperial Bad Guy, Moff Gideon, emerges again, along with the 'Shadow Council' - other" +
            " former Empire bad guys. For someone who's been a bit confused as to where the First Order came from in" +
            " the Force Awakens movie (i.e. how did those clowns get back into power, and why is a Resistance needed" +
            " when they won last time?), it's been great to get some " +
            "of the between Episode 6 and 7 info filled in." ,
        "paragraph_4":"That aside though, the Mandalorian is still mainly just great just fun - space battles, monsters, aliens," +
            " more space battles, Baby Yoda moving stuff with the Force, lots more Mandalorians and just lots" +
            " of fun action. It's Star Wars at its best - beautiful to watch, heroes you can really root for, " +
            "a nice mixture of comedy and serious, it's like the original movies, but without the mediocre bits. (Be honest, " +
            "the original movies aren't always amazing!)",
        "paragraph_3":"Episode 3 of the series, 'Chapter 19: The Convert' is an outlier - it's primarily concerned with " +
            "Dr. Pershing, the scientist that was tasked with doing something with Grogu way back in Series 1. Here, he's" +
            " being rehabilitated with other former Empire folks, in an episode that feels more like it belongs in Andor," +
            " the excellent and much more serious Star Wars show about Cassian Andor. It's a much more talky episode, " +
            "darker in theme, and one that may confuse kids used to the usual Mando gunfighting and Grogu cuteness episodes.",
        "paragraph_5":"We're also incredibly excited by the recent Lucasfilm announcement that there will be a movie" +
            " made by Dave Feloni to \"culminate\" the Mandalorian and related spinoffs - the Book of Boba Fett and the upcoming" +
            " Ashoka (which also looks great!). How they translate it from TV series to a big screen movie we'll have to see," +
            " but we will be there on day one to watch it! Or hopefully, before day one, if we can finally figure out how" +
            " to be invited to press screenings! We're a startup!",
        "paragraph_7":"If you liked this review, please share with your friends on your social media network of choice!",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://press.disney.co.uk/press-kit/the-mandalorian-season-3",
        "spoliers": true,
        "darkTitle": false,
        "summary": "A reassuringly good series, still probably the best Star Wars."
    },
    {
        "name": "The Night Agent No-Spoiler Quick Review",
        "tv": "the-night-agent-review",
        "subtitle": "Solid action and spy fun",
        "date": "8/April/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "night_agent2.jpg", text: "Hong Chau as Diane Farr", alt: "Woman with long white hair standing with an American flag behind her"},
        "image_1": {image: "night_agent1.jpg", text: "", alt: ""},
        "image_3": {image: "night_agent3.jpg", text: "Don't mess with the Secret Service", alt: ""},
        "image_5": "",
        "image_4": "",
        "image_6": "",
        "paragraph_1":"The Night Agent is, as of time of writing, the #1 TV series on Netflix and we can understand why" +
            " - it's a solid, fun action & spy thriller that has the just right balance of gun fights," +
            " car chases, eccentric villains and twists, while also being just a little bit silly; which we love! Some" +
            " shows like The Recruit feel like comedies, The Night Agent takes itself seriously, even if we don't have to." +
            " The trailer's serious 'There's a phone in the basement of the White House, that never rings' had us on board immediately -" +
            " we didn't need to watch anymore to know that, yes, the phone that never rings, rings!  ",
        "paragraph_2":"We weren't surprised that the main star is a guy (Gabriel Basso), the makers of these shows seem to have forgotten" +
            " how good Claire Danes was in Homeland and think that every new spy show has to have a young male hero" +
            " with a troubled past! There are plenty of other strong female characters - Luciane Buchanan as a cyber-security CEO," +
            " Hong Chau as the President's Chief of Staff, Fola Evans-Akingbola as a Secret Service lead and more, but like with" +
            " most shows we watch, we wonder what it would have been like if the female lead, Luciane Buchanan here as Rose, was" +
            " the FBI agent with the troubled past, instead of the guy. " ,
        "paragraph_3":"That lack of diversity aside, this is a good show, the type that will keep folks watching Netflix." +
            " We've watched about half the episodes so far and it is a 'Let's watch one more episode' series that we" +
            " found ourselves wanting to Google how it ends, but wanting to not find out so we can enjoy the twists and turns.",
        "paragraph_4":"Overall, we're giving it 4 out of 5 stars so far, and will be back with a more spoiler-laden review" +
            " after we've finished the series!",
        "paragraph_5":"",
        "paragraph_7":"If you liked this review, please share with your friends on your social media network of choice!",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://www.netflix.com/",
        "spoliers": true,
        "darkTitle": false,
        "summary": "UNDER THE WHITE HOUSE THERE'S A PHONE THAT NEVER RINGS! A bit silly but lots of fun."
    },
    {
        "name": "The Last of Us Series 1 Finale Review (SPOILERS!)",
        "tv": "last-of-us-series-1-finale-review",
        "subtitle": "Are we sure the good guys won?",
        "date": "16/Mar/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "ashley-johnson.jpg", text: "Pregnancy in the apocalypse is no fun", alt: "A scared pregnant lady stands in a forest"},
        "image_1": {image: "pedro-pascal-finale.jpg", text: "", alt: "Very sad looking man"},
        "image_3": {image: "bella-ramsey_6_finale.jpg", text: "Trying to fix the world, one brain removal at a time", alt: "A surgeon and two nurses beside a girl on an operating table"},
        "image_5": "",
        "image_4": {image: "bella-ramsey_5.jpg", text: "The incredible Bella Ramsey", alt: "A girl smiling."},
        "image_6": "",
        "paragraph_1":"Series 1 of The Last of Us has just ended and we're not sure we're ok! Major SPOILERS! follow" +
            " so if you're not up to date, you've been warned!",
        "paragraph_2":"We'll cut straight to the chase - the finale was outstanding, an emotionally crushing episode" +
            " that leaves us unsure how to feel about it. A refrain we often say to our own children is taken from Game of" +
            " Thrones - 'The night is dark and full of terror'. Potentially a tad over the top in our attempts to let our" +
            " kids know that not everyone in the world is nice, but boy, if you ever thought that our world is bad, The Last of Us " +
            " ratchets it up to 11. The finale starts frenetically with a heavily pregnant woman (whom we soon get to realise is" +
            " Ellie's mother Anna) fleeing through a forest" +
            " into an abandoned house, eventually trying to hide in an upstairs bedroom. She is attacked by an infected" +
            " during labour, manages to kill it, but gets bitten, then baby Ellie arrives! Firefly Marlene (who we met" +
            " in episode one, charging Joel with bringing Ellie cross country) arrives just too late, and takes Ellie off Anna" +
            " before shooting her dead, at her own request! Anna's final gift to her baby daughter is the switchblade she " +
            " just used to kill the infected - yes, the same switchblade 14 year old Ellie still possesses." +
            " The whole scene gives us valuable background on Ellie, where her immunity comes from, along " +
            "with Marlene's relationship with her and her mother. It also is a further reminder of just how awful the" +
            " world has become - a \"broken world\" as Marlene later describes it to Joel." ,
        "paragraph_3":"There's more to the episode (Giraffes! More bad puns!) but" +
            " we need to talk about the ending! Joel delivers Ellie to the Fireflies, where Marlene informs a shocked Joel" +
            " that the plan is to kill Ellie to use her brain for research into a cure. Badly miscalculating two things" +
            " - Joel's feelings towards Ellie, and Joel's capability to do something about it, she orders two of her" +
            " henchmen to walk him to the highway and set him free. As he trudges off with a dazed look on his face, a Firefly" +
            " goon pushing him in the back, we know based on what we've seen Joel do throughout the series, that " +
            " they're going to regret it. Moment later, Joel overpowers one Firefly, kills both of them, then " +
            "a remarkable montage is shown of Joel rampaging through the hospital killing what we presume is" +
            " every Firefly in the building. John Wick and similar movies see the hero killing people just as easily" +
            " but there's never any real emotion or reality to it - this is different, shot and scored sadly, with" +
            " closeups of dead Fireflies, along with a Joel who looks like a man stunned that he has to do this, but" +
            " doing it all the same. When he finds Ellie on an operating table, he almost absentmindedly shoots the" +
            " surgeon in the head, killing what is possibly one of humanity's only hopes of a cure. Carrying" +
            " Ellie out and into a car, he also kills a defenceless Marlene in cold blood to stop her from coming" +
            " after Ellie again.",
        "paragraph_4":"How we are supposed to feel about this is the tricky question. Joel promptly lies to Ellie when she wakes" +
            " - he knows she would have likely rather died to help find a cure, whereas he couldn't face the" +
            " thought of losing another daughter. Marlene pleads with him to stop, that it isn't too late, but" +
            " he shoots her and leaves, and tells Ellie that the doctors have stopped looking for a cure. What would" +
            " you do? As a parent to four children, we think we'd do the same, we couldn't just let one of our" +
            " daughters be sacrificed, even for 'the greater good'. We're not totally sure however is Joel" +
            " a 'good guy', or actually one the apocalypse's bad actors, willing for years to do anything to survive," +
            " but now with Ellie to protect. Does his love for her make him a hero, or is he a villain who ruthlessly " +
            " slaughters Marlene and friends for his own selfish reasons?",
        "paragraph_5":"Whatever answer you come up with, it's easy to say that The Last of Us is a remarkable show," +
            " much more than just a video game adaptation. It's a thought provoking, emotional, dramatic, action" +
            " packed journey through a dying civilisation, and we have a sinking feeling that there ultimately" +
            " won't be any happy endings for Joel and Ellie. Already renewed for at least one more series, we'll" +
            " be back to watch more when it arrives, a truly different and noteworthy series that stays with you" +
            " after you've watched it.",
        "paragraph_7":"If you liked this review, please share with your friends on your social media network of choice!",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://pressroom.warnermedia.com/us/property/last-us/images",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Words can't really describe how good this episode is. Just watch and enjoy."
    },
    {
        "name": "The Last of Us Episode 5 Review (SPOILERS!)",
        "tv": "last-of-us-episode-5-review",
        "subtitle": "The Walking Dead on Steroids",
        "date": "13/Feb/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "pedro-pascal.jpg", text: "The Factory of Sadness", alt: ""},
        "image_1": {image: "lastofusep5003.jpg", text: "", alt: "Boy with superhero mask painted on his face"},
        "image_4": {image: "lastofusep5001.jpg", text: "", alt: ""},
        "image_3": {image: "lastofusep5002.jpg", text: "Pyscho Rebel Leader & Badass", alt: ""},
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Episode 5 of the Last of Us continues to show us why this show is \"Have to watch on my phone when I'm cooking dinner\" good." +
            " Major SPOILERS! follow so abandon ship now if you haven't seen up to episode 5.",
        "paragraph_2":"",
        "paragraph_3":"The photo above of Pedro Pascal was the image we used in our review of the first episode, but we've realised" +
            " the scene it's from comes from episode 5 - and we know understand why Joel looks so sad; and we know who" +
            " the shadowy figure is in the background - Ellie, walking away from the graves of two characters that" +
            " they (and us) have just met. The Last of Us reminds us a lot of the Walking Dead, obviously, but it also" +
            " reminds us of Game of Thrones, not just because Pedro Pascal & Bella Ramsey appeared in that also, but " +
            "because it was based on strong source material (at least until the final seasons!!) which resulted in" +
            " it being much more than just a great action show; there was depth and drama galore. The Last of Us is the same" +
            " - it's not simply a Zombie show (though, boy, are those 'zombies' so much scarier than the Walking Dead's!)," +
            " it's a tension filled drama, not afraid to kill off characters that we were growing to like.",
        "paragraph_4":"Now back to those zombies! The Walking Dead has \"walkers\", slowly shuffling and not really that dangerous, although" +
            " the final series began to show \"variants\", capable of a bit more, like opening doors. 28 Days Later had zombies that ran full speed" +
            " at you, much more frightening, but the Last of Us takes it to a whole level. Episode 5 features a rampaging hoard" +
            " of infected, including a frankly terrifying Young Girl Clicker, and also (what we've Googled to find out the name) a \"Bloater\"" +
            " - a huge infected who is seemingly impervious to bullets and who literally tears the head off one of " +
            " the characters who we had thought looked like a bit of a badass ... until his head was ripped off.",
        "paragraph_5":"Episode 5 introduces us to Henry & Sam, two brothers hiding from the rulers of Kansas City -" +
            " whom we find out have ruled for about 10 days, when they overthrew the fascist FEDRA government. From the flashbacks" +
            " scenes of the retribution carried out against FEDRA personel and pretty much any scene with Kathleen, the ruthless leader of the" +
            " resistance, we're not sure if the new rulers will be any different to the old ones. Obsessed with finding" +
            " Henry, she ignores warnings that there's something else worth worrying about - like the pulsing ground" +
            " we saw in the previous episode. The more we watch of the Last of Us, the more we're beginning to think" +
            " it's not really a happy ending type of show, and the Cordyceps fungus is so devastating that any type of" +
            " normal life may not be ultimately possible. So you can imagine what happens when you ignore a mass pulsing " +
            " in the ground ...",
        "paragraph_7":"Joel and Ellie's relationship continues to grow, with his concern for her fully evident in the panicked" +
            " scene where he is trying to save her using a sniper rifle as she is caught up in the chaos of the infected attack." +
            " Rather like the Mandalorian, Pascal's Joel is a man of few words, but somehow conveys so much with so little. He is " +
            "a tortured man in a destroyed world, beginning to care again - and when you care for something in a destroyed world," +
            " you're open to pain and fear. We didn't play the games, so don't know what happens, but we're sure it won't" +
            " be unicorns and cupcakes at the end. Likewise, the horrible deaths of Sam and Henry only reinforce that we're not" +
            " watching the usual zombie show. That, and the crazy huge zombie that can't be killed by bullets!",
        "paragraph_8":"Come back soon for more reviews!",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://pressroom.warnermedia.com/us/property/last-us/images",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Just an absolute cracker of an episode. Have the tissues ready."
    },
    {
        "name": "The Last of Us Episode 3 Review (SPOILERS!)",
        "tv": "last-of-us-episode-3-review",
        "subtitle": "One of the best episodes of television you'll ever see.",
        "date": "30/Jan/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "nick-offerman-murray-bartlett_2.jpg", text: "Do you play?", alt: "Man with beard holds shotgun"},
        "image_1": {image: "nick-offerman_2.jpg", text: "Don't be fooled by the preview images, this episode is about love", alt: ""},
        "image_3": {image: "pedro-pascal_1.jpg", text: "Joel, tortured hero", alt: ""},
        "image_4": {image: "bella-ramsey_6.jpg", text: "Ellie, typical teenager", alt: ""},
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Episode 3 of the Last of Us is one of the best episodes of any television series that we have seen." +
            " Major SPOILERS! follow so don't read if you haven't seen it yet - go find a quiet place and watch!",
        "paragraph_2":"We have watched countless episodes of the Walking Dead, several decent zombie movies like 28 Days Later," +
            " and plenty of not so great, but still fun, ones aswell. This however is the first post-apocalyptic show" +
            " we've seen that devoted more than an hour to a middle-aged men gay romance story. One of the advantages" +
            " of the streaming approach to TV is that shows aren't beholden to set episode lengths. This episodes is about" +
            " 75 minutes, which allows it to take its time telling a beautiful story of Bill, a gruff survivalist" +
            " living alone in a secure compound he's created, meeting Frank, a not so gruff survivor, after the world has collapsed.",
        "paragraph_3":"Joel and Ellie still appear in the episode, in fleeting but important scenes - in one Joel gives some exposition" +
            " as to how the societal collapse happened so quickly - but this is Bill and Frank's episode.",
        "paragraph_4":"As we watched the show we couldn't help but draw parallels with the montage at the start" +
            " of Pixar's Up, a film that on face value couldn't be further away from a 'killer fungus has turned" +
            " everyone into zombies' show! However, those beautiful few minutes in Up, where a lifetime of " +
            "memories from Carl Fredricksen and his wife (also Ellie) are shown, leading to her death. One of " +
            "our daughters really doesn't like Up because of the sadness, but we suspect it was aimed more at " +
            "parents. This episode of The Last of Us is basically a 75 minute version of the same thing - " +
            "20 years of memories of a great relationship.",
        "paragraph_5":"The first episode of the series reminded us a lot of The Walked Dead - not unusual, there's" +
            " probably only so many ways a zombie apocalypse can feel and look like. The second episode though " +
            "veered into its own feel - mainly through the greatly increased sense of horror and fear. We learn" +
            " that the 'cordyceps' virus/fungus is much more terrifying that the Walking Dead's slow walkers - " +
            "the infected sprint, and some become Clickers - human bodies, but blind monster like heads who make a" +
            " simply petrifying clicking sound, used presumably to locate people. Episode 2 also showed us the " +
            "death of Tess, and combined with the further deaths in the latest episode, we've realised that" +
            " The Last of Us is its own beast entirely - a fantastic, frightening, tension filled journey" +
            " through a destroyed world, where the only glimmer of hope may be the caustic teenager Ellie. ",
        "paragraph_7":"Speaking of whom - she kills an infected without Joel knowing, steals a gun, and will most hopefully" +
            " provide Joel with some level of reason to love after everything he has been through, just like Bill" +
            " and Frank. We usually close off reviews by saying we're looking forward to the next episode, but " +
            "in this case we really mean it - the series is becoming must-watch-when-we're-supposed-to-be-working good!" +
            " Call back to NOadsHERE soon for more reviews and discussion!",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://pressroom.warnermedia.com/us/property/last-us/images",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Has to be a contender for episode of TV of the year, just amazing."
    },
    {
        "name": "The Last of Us Episode 1 Review",
        "tv": "last-of-us-episode-1-review",
        "subtitle": "As good as we'd hoped.",
        "date": "16/Jan/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "pedro-pascal.jpg", text: "Pedro Pascal as Joel", alt: "Man looks pensively downwards with background blurred behind him"},
        "image_2": {image: "the-last-of-us_0.jpg", text: "Why does the apocalypse always have graffiti?", alt: ""},
        "image_3": {image: "bella-ramsey.jpg", text: "Bella Ramsey as Ellie", alt: "Girl sitting in a dark room chained to the floor"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"The Last of Us is a new show on HBO (NowTV / Sky Atlantic in Ireland & the UK) based on the" +
            " critically acclaimed computer game of the same name. We actually have had a copy of the game for years but never got around to playing" +
            " it, so our review is strictly from a \"We've heard a bit about the story but not really\" angle." +
            " We were aware however from all the hype that this is a Big Deal, and we're of course huge " +
            " Pedro Pascal AKA The Mandalorian fans - so we'd high expectations for the series," +
            " which we can thankfully say have been met. We'll keep from any spoilers in this post so don't" +
            " be afraid to keep reading!",
        "paragraph_2":"The first episode is thrill ride, full of tension, action, horror, emotion and it held our" +
            " attention right the way through. The overall concepts and themes won't be novel to some viewers - it's" +
            " quite similar to the Walking Dead, or other post-apocalyptic shows, but it doesn't matter as it's made so well," +
            " with great acting. Pascal in the Mandalorian is one of the best, if not the best, Star Wars characters, managing" +
            " to convey emotions and depth even though he has a helmet on nearly all of the time. Here we get to see" +
            " that rugged face and he's perfect for the role - showing both his tender side as a father, and his not" +
            " so tender side that others fear and respect.",
        "paragraph_3":"We've only seen one episode so can't comment yet on the series as a whole, but so far we're" +
            " liking what we see. We want to be careful so not to give any spoilers, but we liked how a lot of the" +
            " episode was filmed - from the perspective of Joel's daughter Sarah. Her confusion at unfolding events" +
            " mirrored our own, as we (who haven't played the game!) are making presumptions on what's about to happen" +
            " but don't know any of the details. There's also a recurring motif of time - watches, clocks etc, that" +
            " has a little High Noon flavour to it - perhaps a bit heavy handed, but the point is clear, time is ticking" +
            " towards something, most likely something terrible.",
        "paragraph_4":"Bella Ramsey (Game of Thrones) as Ellie looks to be full of spirit and she's one of a few strong" +
            " characters, it's not just the Pedro Pascal show. We know enough about that game to know that she's integral to the " +
            " story and again, so far, she looks like she'll be great in the role.",
        "paragraph_5":"In summary, we'll have a longer review once we've watched a few more episodes - but we recommend it, it's" +
            " a tension filled show that obviously has had a lot of work and money put into it, with great actors and " +
            " so much more to come. Come back soon to NOadsHERE to read more about it!",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://pressroom.warnermedia.com/us/property/last-us/images",
        "spoliers": true,
        "darkTitle": false,
        "summary": "Worth the wait, it's as good as we hoped."
    },
    {
        "name": "Jack Ryan Series 3 Review (SPOILERS!)",
        "tv": "jack-ryan-series-3-review-spoilers",
        "subtitle": "John Wick meets James Bond",
        "date": "2/Jan/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "jackryan2.jpg", text: "", alt: "Woman walks down red carpet with honor guard of soldiers and a plane in the background"},
        "image_2": {image: "jackryan1.jpg", text: "What do you mean it was just a cleaner?", alt: "John Wick wannabe stares at his much cooler boss."},
        "image_3": {image: "jackryan3.jpg", text: "Much cooler than an Uber", alt: "Two men in black military clothing and guns walking away from a helicopter."},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"We've just binged the third series of Jack Ryan over the holidays and thoroughly enjoyed it" +
            " - it's a fun, action packed adventure show with plenty of drama, bad-guy Russians, good-guy Russians," +
            " helicopters, warships, nuclear bombs, and more! Mild spoilers follow so leave now if you haven't watched it yet!",
        "paragraph_2":"Probably the main thing we noticed in this series is that Jack Ryan (John Krasinski) has become a sort" +
            " of James Bond / Jason Bourne / One Man To Save The World character, abandoning totally the 'I'm just a Desk" +
            " Analyst' from Series 1. It's actually a bit too much - he kills countless people and we were reminded of" +
            " Gerard Butler in the Olympus Has Fallen movies; expertly shooting bad guys everywhere he goes. It also reminded" +
            " us of the skits in Austin Powers where they show the families of dead henchmen being told the bad news - we couldn't" +
            " help wondering if all the dead Russians have families or friends concerned about them! There's a scene" +
            " where Jack's in the house of a Russian Naval Captain and he shoots dead a guy who comes down the stairs." +
            " Who was that guy? Security? Housekeeper? A family friend visiting? Did that guy have family? Jack Ryan" +
            " certainly didn't ask! We're not necessarily complaining as we like a lot of John Wick style action, but it just seems a" +
            " bit odd to be so ok with killing folks while repeatedly going on about 'Doing the right thing'.",
        "paragraph_3":"Thankfully, there are a lot of other strong characters aswell to make it less of a one man show." +
            " James Greer (Wendell Pierce) is just great again, he looks like he couldn't win a race against a tortoise" +
            " but also looks like he'd easily murder you with his bare hands without breaking a sweat. Elizabeth Wright (Betty Gabriel)" +
            " plays his CIA boss, in a role that's a bit too predictable (New boss doesn't believe the Super Spy's predictions" +
            " of doom but eventually has his back) but she's good and we can see her having more to do in future series. " +
            " There's Czech President Alena Kovac (Nina Hoss), total douche-bag Russian Minister of Defence Alexei Petrov (Alexej Manvelov)" +
            ", Peter Guinness as President Kovac's father, a fantastic James Cosmo as Russian spymaster Luka Goncharov and many more. One (presumed) benefit of Amazon bankrolling" +
            " the show is the quality of the actors they're filling the roles with.",
        "paragraph_4":"",
        "paragraph_5":"All things considered, it's definitely worth watching - a fun, action packed series with enough good acting" +
            " and drama to cover over the wannabe Bourne vibes.",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://press.amazonstudios.com/us/en/original-series/tom-clancys-jack-ryan/3",
        "spoliers": true,
        "darkTitle": false,
        "summary": "James Bond meets John Wick."
    },
    {
        "name": "Andor is a Searing Exploration of the Real Tyranny of the Dark Side",
        "tv": "andor-searing-exploration-of-tyranny",
        "subtitle": "No lightsabers or Sith lords needed here.",
        "date": "4/Nov/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "andorep9_1.jpg", text: "", alt: ""},
        "image_2": {image: "andorep9_3.jpg", text: "We used to sympathise with you!", alt: ""},
        "image_3": {image: "andorep9_2.jpg", text: "The banality of evil", alt: ""},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Andor has become one of the best TV shows of the year, completely unexpectedly" +
            " but to our absolute delight. Major spoilers for all episodes up to number 9 follow so" +
            " leave now if you haven't seen them yet!",
        "paragraph_2":"Star Wars has always been a kids' franchise, from the very first movie to the " +
            " recent Mandalorian series. There have been dark or frightening moments, but on the whole" +
            " they have been family friendly - including the occasional foray into the too kid-friendly; Jar Jar Binks anyone?" +
            " Andor however is not a kids' show - it is a dark series full or murder, death, injustice and tyranny. This" +
            " isn't a show about the Force, or lightsabers, or cute furry aliens on a forest planet. It's not even about" +
            " Darth Vader or the evil Emperor - who, to be honest, have always been both slightly over the top in their" +
            " villainy. Andor is about the actual reality of a cruel and authoritarian empire - not a Sith lord throwing" +
            " people around with his powers, but the harsh and evil people who enforce the Empire's laws, and the impact" +
            " it has on people and justice. It is a searing exploration of the real tyranny of the Dark Side.",
        "paragraph_3":"Denise Gough's Dedra Meero, a supervisor in the ISB (basically like the Nazi's Gestapo), started off a sympathetic character, a woman" +
            " in a man's world, being stymied by male colleagues in the workplace. By episode 9 however we can see who she" +
            " truly is - an enthusiastic and vicious fascist who takes delight in torturing prisoners to extract" +
            " information. She is much more chilling than Darth Vader has ever been - he strides around with his lightsaber," +
            " she stands there in her crisp uniform smiling as another woman screams in pain. This exploration" +
            " of what The Dark Side actually results in - a dystopian society ruled by facists - couldn't be" +
            " anymore topical, with countries like Russia, Iran and China operating in pretty much the same way." +
            " We have wondered - if the leaders of those countries watch Star Wars, would they want the Dark Side to win?",
        "paragraph_4":"The recent Star Wars trilogy has come under a lot of criticism, and we agree with a lot of it." +
            " We love the Force Awakens - a beautiful, exciting movie that brought back the Star Wars magic and had us" +
            " buying lightsabers again! The next two movies however were mediocre at best, awful" +
            " at worst. What we disliked the most was the lack of proper story and background - there was a new First Order" +
            " who had risen after the defeat of the Empire, and they somehow managed to build a massive fleet, including " +
            " a ship built out of a planet, and caused the need for a fresh resistance. What happened in the intervening years?" +
            " Didn't whatever took over after the Empire fell not keep on eye on the remnants, form a proper defensive" +
            " capability and so on! We're probably ranting now - but the point is, imagine if those movies were" +
            " as nuanced and detailed as Andor, if they had taken the time and effort to make them more than" +
            " action and evil Sith lords. Andor is taking its time, developing characters and storylines with care and detail," +
            " and as I was explaining to one our kids - it's Star Wars, but not really.",
        "paragraph_5":"Andor has also upped the stakes in the acting - it is full of great actors and performances," +
            " including Andy Serkis (Gollum from Lord of the Rings) as Kino, a prisoner locked up with Andor who has brought "
            + "an even greater gravitas and passion to the show. Kyle Soller as former Deputy Inspector Karn continues to impress" +
            " as he seems to be heading into some sort of stalker obsession territory with our evil ISB supervisor. Where" +
            " that one is going, we have no idea! If you are new to the show and find the first three episodes a bit slow, " +
            "we highly recommend you stay the " +
            "course - from episode 4 onwards it's been a thrill ride, full of action and tension, and we are genuinely" +
            " excited for what's to come. ",
        "paragraph_7":" Come back here for more reviews, but for now we'll leave you with one exciting thought that will" +
            " makse sense if you're up to date ... never more than twelve.",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://www.starwars.com/series/andor/",
        "spoliers": true,
        "darkTitle": false,
    },
    {
        "name": "The Peripheral No-Spoilers Mini-Review",
        "tv": "the-peripheral-no-spoiler-mini-review",
        "subtitle": "Science fiction and more.",
        "date": "25/Oct/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "peripheral3.jpg", text: "Chloe Grace Moretz as Flynne Fisher", alt: "Blonde woman with futuristic headset"},
        "image_2": {image: "peripheral1.jpg", text: "Eli Goree as Conner Penske", alt: "Man smiling"},
        "image_3": {image: "peripheral2.jpg", text: "Looking as confused as we feel", alt: ""},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"The Peripheral is a new science fiction show on Prime Video and this is our No-Spoiler" +
            " Mini-Review after watching the first two episodes! We thought about including spoilers so we could" +
            " really discuss what's going on, but we kind of stumbled into the show not nothing anything" +
            " about it, so in case you're the same, we suggest just watching it without reading any" +
            " detailed recaps or reviews. We will have more reviews in the upcoming future as we already have the feeling that" +
            " there's going to be lots of theories and discussion points in this one, but for now" +
            " this is just a 'mini' review to hopefully help you decide whether to watch or not.",
        "paragraph_2":"So, what can we say, without spoiling anything? The Peripheral is a science fiction show," +
            " not set in space, but on Earth, and we've really enjoyed the two episodes we've seen. The main" +
            " star is Chloe Grace Moretz, who's been more of a movie actress, but there's a number of other" +
            " strong characters, including Jack Reynor as her brother, Gary Carr as Wilf and Louis Herthum as Corbell Pickett." +
            " The show was created by Scott Smith but also produced by Jonathan Nolan and Lisa Joy - who we know from" +
            " Westworld, the initially amazing but recently not so amazing science fiction show set in a near future full of" +
            " artificial intelligence, robots and lots & lots of violence!" +
            " Westworld is full of twists and turns, confusing timelines and storylines, and they seemed to take a " +
            "delight in really keeping viewers in the dark about what was going on. We wonder will the Peripheral" +
            " be the same - and are things really what they seem, or will the wool be withdrawn from our eyes" +
            " at some stage.",
        "paragraph_3":"We think we're almost about to veer into spoiler territory so we're going to leave it there" +
            " for now. There's action, good acting and characters, cool technologies" +
            " and plenty of surprises - we recommend you go try it out and come back here shortly for some longer discussion reviews" +
            " on what may or may not be real or not real! ",
        "paragraph_5":"",
        "paragraph_4":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://press.amazonstudios.com/",
        "spoliers": true,
        "darkTitle": false,
    },
    {
        "name": "Andor Episode 4 Review (SPOILERS!)",
        "tv": "andor-episode-4-review",
        "subtitle": "The plot thickens in this more nuanced view of Star Wars",
        "date": "4/Oct/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "andor6.jpg", text: "", alt: "Woman in white uniform looking serious"},
        "image_2": {image: "andor8.jpg", text: "Genevieve O'Reilly as Mon Mothma & Stellan Skarsgård as Luthen Rael", alt: "Woman talking to man in gallery"},
        "image_3": {image: "andor7.jpg", text: "A motley crue", alt: "Group of people in forest"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"With a fantastic Episode 4, Andor is feeling like a more maturely developed series now, with multiple" +
            " storylines, characters and overall a more detailed and nuanced view of the Star Wars universe. The recent Book of " +
            " Boba Fett, which we enjoyed, is an example of a much more simple show, and one that didn't look or feel too 'real'." +
            " Andor is the opposite - a gorgeous looking show, where the sets - whether in city or in forest - just look" +
            " more realistic, and the plot has been given a lot more thought.  ",
        "paragraph_2":"Before we continue - here be SPOILERS! so leave now if you haven't watched it yet.",
        "paragraph_3":"An essential part of any decent show is interesting characters and here is where Andor shines." +
            " We obviously have Cassian Andor, who we know ends up a rebel hero but for now is more of a mercenary" +
            " just trying to survive, but now we've been introduced to a little gang of rebels who generally aren't" +
            " too happy that Andor has joined their group. Stellan Skarsgård as the rebel / spy / antique dealer Luthen Rael is great, " +
            "bringing intensity, anger and guile to the show, and in episode 4 we're introduced to Senator Mon Mothma, played" +
            " by Genevieve O'Reilly, who is secretly helping Rael, and seemingly also juggling a bad marriage! I quick " +
            " Google confirmed my vague recollections that Mon is a character in movies 3, 6 and Rogue One (proper Star Wars " +
            "fans, please don't stop reading now!), but even if you didn't know that, her brief scenes hold promise for" +
            " more intrigue and drama.",
        "paragraph_5":"Some may criticise the backwards looking slant of this and the other new Star Wars shows - they're all" +
            " set in between and before previous movies, instead of being completely brand new or in the 'present' of" +
            " the Star Wars universe (as present as something A Long Time Ago can be). This set-in-the-past approach" +
            " obviously has limitations, as they need to stick to facts from the movies, but I find Andor to be" +
            " a worthwhile addition - showing more detail, in its own interesting story, of a period in the timeline" +
            " that hasn't been seen much in the movies.",
        "paragraph_4":"Andor also focuses on the antagonists - ep. 4 introducing Denise Gough's Dedra Meero, an officer" +
            " in the Imperial services - and also the slightly hapless Deputy Inspector Karn (Kyle Soller) - now fired" +
            " and literally gone home to his mother! This attention to the 'bad guys' and not just in a Look At Us" +
            " We're So Amazingly Evil way, gives more shades of grey and deepness to the show. Should we as viewers feel empathy" +
            " for Dedra, a woman in a leadership role being blocked by her male colleagues, even though she works for the" +
            " Dark Side? And should we root for the sad Karn, whose motivation into chasing Andor was the killing of " +
            "two of his staff? Not your usual Star Wars questions!",
        "paragraph_7":"In summary, Andor continues to hold interest, and we're looking forward to seeing Andor and his " +
            "new gang attempt their audacious raid in upcoming episodes. Comes back to NOadsHERE for more reviews soon!",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://www.starwars.com/series/andor/andor-season-1-episode-4-episode-guide",
        "spoliers": true,
        "darkTitle": false,
    },
    {
        "name": "Andor Episodes 1 to 3 Review (SPOILERS!)",
        "tv": "andor-ep-1-2-3-review",
        "subtitle": "A promising start to a different type of Star Wars.",
        "date": "24/Sep/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "andor1.jpeg", text: "Even the poster looks cool", alt: "Andor name on poster"},
        "image_4": {image: "andor3.jpeg", text: "The always amazing Fiona Shaw", alt: "Lady in chair beside lamp"},
        "image_1": {image: "andor2.jpeg", text: "", alt: "Two men on speeder motorbike"},
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Andor is a new Disney+ Star Wars series and three episodes in, it shows promise of " +
            "being different to what we've seen before. If you haven't seen these episodes yet you may" +
            " want to leave now, as SPOILERS!",
        "paragraph_2":"In the Star Wars timeline, it is set before the Rogue One movie, which was positioned" +
            " between episodes 3 and 4 and told the story of how rebels obtained the plans for the Death Star" +
            " for Princess Leia. Diego Luna played Cassian Andor in that movie, and he is the star of this" +
            " new series. Rogue One is one of our favourite Star Wars movies - it is beautifully made and is more" +
            " gritty and realistic (as realistic as a Stars Wars movie can be!) - to the extent - MORE SPOILERS! -" +
            " that Cassian Andor dies at the end of the film, which is unusual for what is basically a kids' series.",
        "paragraph_3":"Andor is rated as 12+ on Disney+ and we'd agree with that - this isn't really a kids' show," +
            " but a more nuanced, adult approach to Star Wars. In the first episode we are introduced to our hero," +
            " Cassian Andor, who is seemingly searching for his lost sister. All normal fare so far - until he" +
            " accidentally kills a security guard who was trying to rob from him, then kills the man's partner" +
            " in cold blood. Not exactly Jedi-style saintly behaviour!",
        "paragraph_5":"This non black or white style of Star Wars is welcome, and offers something different to " +
            "the other Star Wars series and movies. The Mandalorian is the best of them all and easily one" +
            " of our favourite shows in years, but it is firmly in the" +
            " Good Guys & Bad Guys camp. Andor seems to be going a different way, showing a more darker and" +
            " conflicted view of what is essentially a rebel insurrection in a dictatorship. Although by episode" +
            " three Cassian has not yet joined the Rebel Alliance, Stellan Skarsgård appears as Luthen Rael " +
            " who will now likely recruit Andor - and we will start to see more of that rebel story.",
        "paragraph_6":"One advantage Disney has in making shows like this is the ability to get fantastic actors, " +
            "even for small roles. The marvellous Fiona Shaw pops up as Cassian's adoptive mother, an actress we" +
            " absolutely loved in Killing Eve. We're also looking forward to seeing Ireland's Denise Gough in upcoming" +
            " episodes!",
        "paragraph_7":"In summary, we've seen enough in the first three episodes to have us looking forward to the" +
            " rest of the series - it's a different type of Star Wars show, with great acting, sets and action.",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://dmedmedia.disney.com/disney-plus/andor",
        "spoliers": true,
        "darkTitle": false,
    },
    {
        "name": "The Rings of Power Ep. 1 & 2 Review",
        "tv": "rings-of-power-ep-1-and-2-review",
        "subtitle": "Beautiful, epic and a joy to watch.",
        "date": "4/Sep/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "RPAZ_S1_FG_Still00003_C.JPG", text: "An Elven Watchtower, just one of many beautiful scenes", alt: "A stone elven watchtower looks over a beautiful valley"},
        "image_3": {image: "CREDIT_BEN_ROTHSTEIN_PRIME_VIDEO_00250_R3.jpg", text: "Sophia Nomvete as Dwarf Princess Disa", alt: "A Drawf Princess standing in front of wall covered in runes."},
        "image_1": {image: "CREDIT_MATT_GRACE_PRIME_VIDEO_00338_R5_1.jpg", text: "Morfydd Clark as Galadriel", alt: "Female warrior in armour in front of village"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Lord of the Rings: The Rings of Power is the long awaited fantasy series from Amazon set in the same world as the Hobbit" +
            " and the Lord of the Rings, and it is beautiful, epic and a joy to watch." ,
        "paragraph_2":"For those who may not of heard much about it, it's the most expensive TV series ever made, and all that" +
            " Amazon money has been clearly well spent as this is of expensive-movie quality. We are major fans of the three" +
            " Peter Jackson LOTR movies and their magnificent realisation on-screen of the world of Tolkien's Middle-Earth" +
            " has given them a timelessness that has led to us watching them countless times. The Rings of Power is the same" +
            " - a beautifully constructed show that looks like it is in Middle-Earth, with Elven cities, great battles," +
            " Dwarf mines, and more. It is set several thousand years before the events of movies, but several characters, as their younger selves," +
            " are present - including Galadriel (played fantastically by Morfydd Clark) and Robert Aramayo as a much more exuberant and cheerful Elrond.",
        "paragraph_3":"We're not going to spoil anything in this review, but mention a few things we especially liked. The LOTR films" +
            " were quite simplistic in places - not necessarily a bad thing, just a fact. In one scene in the Rings of Power, the Elven King" +
            " gives a speech which we saw Elrond writing earlier. This speech-writing points to a more political and nuanced culture" +
            " and civilisation, not just the broad strokes that the Elves and Dwarves are painted with in the movies. Likewise, we see an" +
            " incredible Dwarf city, and finally meet Dwarf women, in another sign that this series will give a deeper and more diverse level of detail" +
            " to Tolkien's world.",
        "paragraph_5":"Morfydd Clark as Galadriel is marvellous - not yet the stately Queen we meet in the movies, but a young (relatively, she's immortal!)" +
            " warrior full of passion and fight as she seeks to find an evil that nobody else believes still exists. It is wonderful to see a woman" +
            " headline such a major show, and the diversity of casting is throughout - including Lenny Henry as leader of the Harfoots (a kind of earlier Hobbit, we're guessing)," +
            " Ismael Cruz Córdova as an Elven warrior and Nazanin Boniadi as the healer Bronwyn. We especially like Markella Kavenagh as" +
            " Nori, one of the Harfoots, and possessor (like the rest of her folks) of a lilting Irish accent! As Irish ourselves, we wonder" +
            " if someone writing or producing the show is Irish, even more so with the inclusion of \"He's bleedin' massive\", which" +
            " is straight off the streets of Dublin!",
        "paragraph_6":"We've seen the first two episodes, with new episodes arriving each Friday, and we'll be watching it as soon as it comes out. " +
            " In the streaming-world where we also have the Sandman, House of the Dragon and the Wheel of Time," +
            " the Rings of Power may the best of new fantasy show of them all, and its quality and detail is wonderful to watch. Come" +
            " back here to NOadsHERE for reviews of future episodes, along with more spoiler-filled articles as we discuss" +
            " the show with more detail and speculate on some of the mysteries being teased. If you liked this review, please share with your friends!",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://press.amazonstudios.com/",
        "spoliers": false,
        "darkTitle": false,
    },
    {
        "name": "House of the Dragon Ep 1 Review",
        "tv": "house-of-the-dragon-ep-1-review",
        "subtitle": "Love the dragons but miss the white walkers",
        "date": "26/Aug/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "steve-toussaint.jpg", text: "Steve Toussaint as Lord Corlys Velaryon, the Sea Snake", alt: "Man in impressive dreadlocks sitting at table"},
        "image_4": {image: "matt-smith_0.jpg", text: "Our future halloween costume", alt: "Matt Smith in armour holding a lance"},
        "image_1": {image: "house_of_dragon.jpg", text: "Milly Alcock as Princess Rhaenyra Targaryen", alt: "Girl in medieval dress"},
        "image_3": {image: "paddy-considine-rhys-ifans.jpg", text: "Whispered news is never good news", alt: "Man whispering news in ear of other man at jousting tourney crowd."},
        "image_5": "",
        "image_6": "",
        "paragraph_1":"House of the Dragon is a new prequel to Game of Thrones, set some 200 years before the Jon Snow, Daenerys, Arya and the rest of our" +
            " favourites roamed Westeros. In short, the first episode was great, a big expensive looking treat full of " +
            " dragons, knights, Targaryens and more! SPOILERS ahead of Game of Thrones, so leave now if you haven't seen it " +
            "the end of that series. Though, let's be honest, missing that wouldn't have" +
            " been a bad thing!",
        "paragraph_2":"House of the Dragon is all about the fall of House Targaryen, the dragon riding Valyrians who" +
            " conquered the seven kingdoms and who are eventually overthrown just before the events of Game of Thrones." +
            " As befits a show set in the same kingdom, episode one is already starting on the political intrigue -" +
            " who will be King Viserys' heir, who gets to sit on the Small Council, already setting up whatever" +
            " dramas and fighting is sure to come.",
        "paragraph_3":"The final series of Game of Thrones was widely derided as being too rushed and a poor end to a" +
            " fantastic show. We believe there are many possible reasons for the major decline in quality, but we suspect" +
            " one factor was the show running out of the source material. Game of Thrones was so good because the books" +
            " were so good - George R.R. Martin wrote a masterpiece, creating an incredible world and characters that" +
            " translated so well to the screen. By series 8 however, the showrunners were relying on Martin's details" +
            " on his plans - not actual books. We mention this, as House of the Dragon is based off a book 'Fire and Blood'," +
            " and already in episode one we can see detailed, interesting characters and storylines, and know (from reading the book) " +
            " that there is much more to come.",
        "paragraph_5":"One potential problem with the show, and we say potential as we don't know if or how they will" +
            " diverge from the book, is the distinct lack of white walkers, the apocalyptic villians that" +
            " made Game of Thrones more than just different noble houses squaring off. A series that may seem slightly" +
            " crazy to compare to is the Mentalist, the Simon Baker led show where the main villian was Red John, a serial killer" +
            " whose impact and presence lasts across 6 series. When (more SPOILERS!) Red John was finally dealt with," +
            " the show was still good, but just not as good. We fear that House of the Dragon," +
            " while great, won't have the same over arc-ing danger to keep us interested over the long term.",
        "paragraph_6":"For now though, we're looking forward to the next episodes - the acting is great, the sets look" +
            " expensively realistic and the dragons are everything we hoped they'd be. Matt Smith is also marvellous" +
            " as Prince Daemon Targaryen, and not just because he's our favourite Doctor Who! Come back here to read more reviews, " +
            "and please share with your friends if you'd like!",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://pressroom.warnermedia.com/",
        "spoliers": false,
        "darkTitle": false,
    },
    {
        "name": "Westworld Series 4 Review (SPOILERS!)",
        "tv": "westworld-series-4-review-spoilers",
        "subtitle": "A disappointing and sad end to a once great show.",
        "date": "21/Aug/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 2,
        "wifereview": "",
        "kidsreview": "",
        "image_2": {image: "aurora-perrineau-aaron-paul.jpg", text: "Don't go! Caleb and daughter.", alt: "Man and woman looking into each other's eyes"},
        "image_1": {image: "ed-harris.jpg", text: "", alt: "Man in black walking out of mist"},
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Series 4 of Westworld has just ended and in short, it started with great promise but ended as a disappointing mess" +
            " that we have major issues with. Major SPOILERS follow so we suggest you leave now, or actually maybe stay, let us fill you in" +
            " and you won't have to waste your time watching a show that has just gone downhill.",
        "paragraph_2":"Where do we start? Series 4 started off about 7 years after Series 3, with Caleb now married with a daughter, Meave hiding out" +
            " in a cabin in the woods, Bernard coming out of The Sublime, and Dolores seemingly in some new simulation or role as 'Christina'." +
            " For the first few episodes we were enthralled, eagerly waiting the next week's episode, but then there was another time" +
            " jump - and my word did some stuff happen in the meantime! Basically, Charlotte Hale (a version of Dolores that went " +
            "even more rogue than the original Dolores did) came up with a way using flies to take over and control humans - leading" +
            " to the entire (we think, it's never actually confirmed) world being now under the control of hosts! So the show" +
            " had gone full circle from a park where humans controlled hosts, to a world with the opposite.",
        "paragraph_3":"Our first problem with this is how quickly the show just jumped to the endgame i.e. by the way, the robots have" +
            " won. There's almost no details, apart from a few mentions of cities and 'there's millions of them' (humans)" +
            " but we're not sure has Charlotte killed off the remaining billions or are they out there being controlled aswell. " +
            "The city under control looks relatively normal - albiet everyone stuck in loops just like the hosts used to be in the" +
            " Westworld park - but what about things like industry and farming - is that still going? Are there humans being controlled" +
            " to maintain services to support the cities of controlled humans? Maybe it's pointless asking these questions but" +
            " when you watch a show like this, there's always lots of questions, which is usually a good thing, but after" +
            " watching the last few episodes, we're just asking ourselves, why? It's like someone was making a series about " +
            "World War 3 and just jumped to 'and France won and now control everything'.",
        "paragraph_5":"We suspect heavily that there won't be a series 5, though of course we may be wrong. Series 4 ended with Dolores" +
            " (who turned out to be inside a simulation controlling the 'stories' for all those controlled humans in the city)" +
            " being uploaded to the Sublime (home of all the virtual hosts who escaped there in Series 2), where she planned" +
            " to run one final 'Test', and the scene promptly changed to being back in the original park. We just don't know" +
            " how the show could go any further - they've basically killed off nearly all humans and hosts, and to us it feels like an " +
            " attempt at a clever ending to their entire story. It also felt a lot like the end of Game of Thrones, where the final" +
            " series was rushed and overall just left a bad taste after seven incredible series of amazing television. ",
        "paragraph_6":"If" +
            " Westworld does return, we hope it's better than what we just watched. If it doesn't we still have the memories of a once " +
            "great show. There's also one small positive, that isn't really a positive! We recently realised that we tend to" +
            " only give good reviews on this site, probably because we only watch things that we think will be good! Westworld" +
            " however has helped change that!",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://pressroom.warnermedia.com/",
        "spoliers": false,
        "darkTitle": false,
    },
    {
        "name": "The Sandman Episode 1 Review",
        "tv": "the-sandman-episode-1-review",
        "subtitle": "Everything we hoped it would be.",
        "date": "3/Aug/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "sandman.jpeg", text: "", alt: "Tom Sturridge looking totally cool as the Sandman"},
        "image_2": {image: "sandman2.jpg", text: "Let's be honest, we all want to be as cool as this guy", alt: "Dream looking just as cool as ever"},
        "image_3": {image: "sandman3.jpg", text: "Kirby Howell-Baptiste as Death", alt: "Kirby Howell-Baptiste as Death"},
        "image_4": {image: "sandman4.jpg", text: "Mason Alexander Park as Desire", alt: "Mason Alexander Park as Desire"},
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Let's make this easy before we jump into the details - The Sandman's first episode is everything I hoped" +
            " it would be, a beautiful, well-made, important event and I can't wait to watch the rest of it.",
        "paragraph_2":"For anyone who hasn't heard of The Sandman, it is a new series on Netflix, based on a comic / graphic novel" +
            " that is considered one of the best and most influential of all time. Written by Neil Gaiman - author of" +
            " American Gods, Stardust, Good Omens (with Terry Pratchett), Neverwhere and more - The Sandman " +
            "is the tale of Dream, one of the Endless, whose brothers and sisters include Death, Desire, Despair and others." +
            " Trying to describe what The Sandman is like is difficult, as it involves dreams and nightmares and can wildly vary" +
            " in setting, tone and characters throughout. When writing this review, I had a thought that it's kind of like" +
            " trying to review the Sistine Chapel by attempting to draw my own picture of the Sistine Chapel. Gaiman is one of " +
            " the most creative writers we have had, his novels vibrant, different, dark, playful, mysterious, fun - and so much more." +
            " I feel that I don't have the skill with words to describe how good the comics are, or to fully convey their" +
            " scope, imagination and vision.",
        "paragraph_3":"I can however attest that the first episode (seen via a preview, the rest of the series drops this Friday) " +
            " is just as good in my mind as the comics. Neil Gaiman is heavily involved in this adaptation, and his loving touch" +
            " is all over it. He has been very active and vocal promoting the series, effuse in his praise and making clear" +
            " how happy he is with the end result, which led me to having high hopes, which have been realised. This is a " +
            " well-made show, with great acting, expensive looking sets and effects, and very close to the source material. I" +
            " loved the pacing of the episode - it wasn't rushed, giving us time to meet some of the characters and establishing" +
            " some of the who and the what. Tom Sturridge plays Dream, who I'm guessing must have been a role sought after by" +
            " many actors. From the initial episode, he seems perfectly cast, a mix of brooding, mystery and danger that we" +
            " immediately want to know more about. I suspect this will be a career defining role for Sturridge, one he will" +
            " eventually become most known for.",
        "paragraph_5":"I don't want to give any spoilers for anyone who hasn't read the graphic novels, so am limited in what we can" +
            " discuss today, apart from mentioning that Charles Dance (Tywin Lannister in Game of Thrones) is as impressive as ever." +
            " I can however say that The Sandman to me is a much needed, diverse, series that I think will enthrall and obtain many many new fans" +
            " all across the world. I recently listened to John Carpenter (director of movies such as The Thing and Escape from New York)" +
            " on the Big Picture podcast with Sean Fennessey - and Carpenter talked of the movies he likes to make. He said (I'm paraphrasing)" +
            " that the supernatural isn't real, so it's his job to show us it on screen. The Sandman to me is that - showing us" +
            " the fantastic, the impossible, the wonderful, in a thankfully excellently crafted series that is unlikely to" +
            " disappoint old or new fans. From reading the comics (and the trailers, so we're not spoiling!), I know there is so much more to come " +
            "and to look forward to, from meeting Death & Desire to Dream journeying into Hell itself.",
        "paragraph_6":"Come back to NOadsHERE over the weekend, after we binge the entire series and report back with more reviews!" +
            " If you like this review, please share with your friends!",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"Netflix",
        "spoliers": false,
        "darkTitle": false,
    },
    {
        "name": "Westworld S04 E03 Review (SPOILERS!)",
        "tv": "westworld-series-4-episode-3-review-spoilers",
        "subtitle": "The plot thickens!",
        "date": "11/July/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "jeffrey-wright_0.jpg", text: "Return of Felix Leiter. Jeffrey Wright as Bernard", alt: "Bernard sitting in a diner."},
        "image_3": {image: "luke-hemsworth.jpg", text: "Who needs Thor? Luke Hemsworth as Stubbs.", alt: "Luke Hemsworth sitting in a diner."},
        "image_2": {image: "aaron-paul-thandiwe-newton_0.jpg", text: "Aaron Paul & Thandiwe Newton enjoy a quiet drink.", alt: "Evan Rachel Wood as Dolores."},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"We've just watched episode 3 and it's fair to say that we're completely hooked again on this show. " +
            "Westworld episodes arrive once a week AKA Old School TV, which is actually incredibly frustrating! Our first TV" +
            " as a kid in the 80s was a 14 inch with a few buttons on the side, so you may think we could cope with" +
            " waiting a week, but the last few years of binge watching shows has ruined us! We like it though," +
            " and think it gives a show some more meaning and cultural impact if it's not just all thrown at you in one go. " +
            " Obviously there's exceptions to this - Stranger Things - but with Westworld we're back to " +
            " googling things like \"Is Caleb a host?\" and actually looking forward to Mondays now when we can watch the latest episode.",
        "paragraph_2":"Anyway, SPOILERS ahead so leave now if you haven't seen episode 3 of Westworld series 4!",
        "paragraph_3":"Episode three has three plotlines in motion - Caleb & Maeve exploring the new Westworld park AKA the 1920s," +
            " , Bernard emerging from a multi-year visit to the Sublime and setting off with Stubbs to meet what seem to be" +
            " some sort of resistance fighters in the desert, and Caleb's wife and daughter realising that Caleb's" +
            " friend is actually not a friend at all, and likely a host. There's no room for Dolores this week, apart from the Man in Black" +
            " who is actually a different version of Dolores, gone bad, we think? Keeping track of who is who is getting harder, let's be honest! ",
        "paragraph_4":"Caleb and Maeve's trip to the new park just screams TRAP but they seek out the lower level anyway - where" +
            " the hosts are controlled and fixed, but that turns out to be also part of the game, with a further lower level" +
            " being the actual control centre. It's here that we finally see what's going on with the flies - they're being " +
            "infected with some type of black sludge which allows Bad Dolores / Man in Black / Fake Charlotte Hale" +
            " to use them to control humans. We haven't looked at flies the same since the new series started, and it wasn't" +
            " helped by Caleb being tricked into trying to save his daughter (\"she's a host!\" we were screaming at my TV)" +
            " and then attacked by a swarm of flies. We're hoping it's like Loki taking over Hawkeye in the Avengers, where the" +
            " control didn't last long, but we're fearing that Caleb's time on the show is nearing its grisly end. He's one of " +
            " our favourites - and like we discussed last week, potentially one of the only actual humans left on the show - " +
            " so we hope he survives and gets rid of those terrifying insects from his brain!",
        "paragraph_5":"Bernard and Stubbs storyline doesn't reveal too much either (Westworld likes to draw things out)" +
            " but one interesting wrinkle is that quite like Dr Strange, also in an Avengers movie, Bernard has seen" +
            " all possible futures (via his stint in the Sublime where one year in the real world equals one thousand years in the Sublime)" +
            " and now basically knows what's going to happen before it happens. So he kills some hosts, then heads to the " +
            " desert to offer to find a weapon that the resistance fighters are looking for.",
        "paragraph_6":"We don't know of course when this is happening - Bernard's storyline  may be before or after Caleb & Maeve's" +
            " and we probably won't find out for a while, so feel free to wildly speculate, because we certainly are! It wouldn't" +
            " surprise us if there's a major timeline related twist involved, but then of course the writers know that we may suspect" +
            " that, so maybe the real twist will be that there is no twist, but then of course the writers also know that ....! " +
            "Like we said, endless speculation!",
        "paragraph_7":"One thing" +
            " we are loving in the new series is the appearance of old characters - Stubbs is one who probably isn't" +
            " essential to the plotline, but it's nice for Bernard to have a buddy on his journey - and in the Sublime," +
            " actor Zahn Mcclarnon appears again as Akecheta, the Ghost Nation Native American. Here he's sharply dressed in a nice suit" +
            " and provides some much needed expostion for Bernard and us, the viewers. Akecheta is easily our all-time favourite Westworld character, " +
            " with his amazing performance and storyline in Series 2 Episode 8 one the best episodes of " +
            " any TV show that we've seen. Bernard asked him if he could help, and our hopes were raised that he'd join him" +
            " in the real world, but he declined, preferring to stay in the virtual worlds of the Sublime." +
            " It's better to have loved and lost though, so it was great seeing him again, even for just a few scenes.",
        "paragraph_8":"We do however have a theory that the encrypted data stored in the Hoover Dam, that the Man in Black" +
            " is trying to access, is where the Sublime actually resides physically. So we also wouldn't be surprised if" +
            " it is endangered at some point this series, and the hosts within have to escape back into the real world, leading" +
            " to Akecheta and other old friends joining in the fight.",
        "paragraph_9":"In summary, another strong episode in a reinvigorated show. If you liked this post please" +
            " feel free to share with your friends or on your social network of choice!",
        "paragraph_10":"",
        "image_sources":"https://pressroom.warnermedia.com/",
        "spoliers": false,
        "darkTitle": true,
    },
    {
        "name": "Westworld Series 4 Episodes 1 and 2 Review (SPOILERS!)",
        "tv": "westworld-series-4-epsiodes-1-and-2-review-spoilers",
        "subtitle": "A great return for a great show.",
        "date": "4/July/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "westworld1.jpg", text: "Bond, James Bond. Aaron Paul as Caleb", alt: "Aaron Paul as Caleb, looking rather dapper in a tuxedo."},
        "image_3": {image: "westworld2.jpg", text: "Once tried to fire Maverick. Ed Harris as William", alt: "Ed Harris as William, also looking quite dapper in a suit."},
        "image_2": {image: "westworld3.jpg", text: "What's going on inside? Evan Rachel Wood as Dolores, maybe", alt: "Evan Rachel Wood as Dolores."},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"The 4th series of futuristic Westworld has just launched on HBO (or SKY Atlantic / NOW TV depending where you are)" +
            " and two episodes in we're quite enjoying it. If you haven't seen them, continue at your own peril as" +
            " SPOILERS! It's impossible to talk about a show like this without actually talking about it!",
        "paragraph_2":"Before we dive in, we need to try to remember what happened in series 3, which was a couple of years ago" +
            " due presumably to the pandemic. We're proceeding as if you've seen the first 3 series (or at least the first 2 as" +
            " anecdotally I've heard series 2 wasn't as popular). If you haven't, not much in this post is going to make much sense! " +
            "Series 3 was set mainly in the 'real' world, after Dolores escaped from the park with some unidentified 'pearls' (robot brains)" +
            " and embarked on a campaign to stop a new AI system seeking to control the world. Very different to the first two" +
            " series, I still enjoyed it, if even for just the depiction of future technology and its societal" +
            " impact. It ended with Dolores seemingly dead, after Maeve and newcomer Caleb (Aaron Paul from Breaking Bad)" +
            " helped her destroy the new AI. It's actually way more complicated than that, but we'd be here all day, so we'll" +
            " presume we all remember everything and move on!",
        "paragraph_3":"The first two episodes of series 4 are seemingly set 7 years later, but as this is Westworld, which" +
            " has a history of multiple timelines, who actually knows when, or indeed where the action is taking place." +
            " Caleb now has a wife and daughter, but also a healthy does of PTSD and paranoia, which isn't" +
            " helped by the fact that he of course has every reason to be paranoid. Maeve is living in a remote cabin on her own," +
            " and eventually ends up back with Caleb, just in time to drive her katana through an assassin who was vindicating" +
            " all that paranoia.",
        "paragraph_4":"That storyline looks real, but Dolores' storyline has me speculating we're looking at " +
            " some sort of simulation that she doesn't realise she's in. Each morning she wakes up in bed, just like she did" +
            " when she lived in the park as a host. She's now 'Christina', who writes storylines for computer games, with the " +
            " complication that she's being stalked by some guy who tells her that the story she wrote for his life has" +
            " ruined it. I could be completely wrong, but the slightly repetitive nature of Dolores/Christina's" +
            " world seems like it's fake. There could be any numbers of reasons for this - she's been captured" +
            " by someone who's trying to find information out from her, she's caught in a loop somewhere of her own making" +
            " or pretty much any other crazy reason you can think of! We'll presumably find out soon as she does.",
        "paragraph_5":"The main villain of the show appears to be other hosts who Dolores helped free before. Now masquerading" +
            " as the Man in Black, William, along with Charlotte (Tessa Thompson) and also controlling multiple humans" +
            " via some sort of creepy robot flies (nanotechnology we're guessing), they seem to be going for the classic Let's Take Over" +
            " The World plan, which should be fun! Ed Harris as the robot Man in Black is fantastic as always, just a riveting" +
            " screen grabber whose sheer evilness makes tuning in worth it. The real William is also fleetingly shown, apparently" +
            " being kept alive 'as to be a winner there needs to be a loser', a callback line to one of the first things he said in the" +
            " show's first episode. We'd expect him to be freed at some stage as part of a redemption arc, but maybe we're" +
            " reading too much into it. Maybe sometimes, robot murderers keep around their human adversaries, just because sometimes " +
            "robot murderers keep around their human adversaries!",
        "paragraph_6":"Episode 2 ends up with Maeve and Caleb arriving at a new Westworld park, opened with the help of " +
            " the Vice President who is either a host or under the control of them, and seemingly in mainland US, as oppose" +
            " to 'Out West' like the old park. It looks like 1920s America, so I'd expect we could be treated to some cool looking suits " +
            " and tommy gun action soon. We haven't seen Bernard yet, who we last saw in Series 2 visiting virtually The Sublime - a " +
            " virtual utopia for hosts to live. What he learned there, or why he came back we'll hopefully find out soon, but like we said," +
            " Westworld likes to confuse and confound so who knows what direction any of these or future storylines will take.",
        "paragraph_7":"A note on Caleb, who can seem out of place in a world full of killer robots, kind of like in the Transformers" +
            " movies where the human protagonists kind of just run around as the massive robots do the actual fighting. I think that's" +
            " why Caleb is here though - as more and more of the characters are either hosts, turned into hosts, or under the control" +
            " of hosts, he's one character that we (as humans!) can perhaps identify with a bit better. That being said, if it" +
            " turns out eventually that he is a host also, or that his wife or daughter are hosts, we wouldn't be surprised one bit." +
            " In the meantime, I think he continues to be a great addition to the show and will hopefully give it some more" +
            " human meaning as we continue.",
        "paragraph_8":"In summary, this show has drawn us back in and we're back speculating wildly about what's going on! It's" +
            " a fantastic looking show with its vision of a future world and its wide scope of stories and timelines. " +
            " Come back to NOadsHERE next week for a review of episode 3, for which we're already waiting impatiently.",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://pressroom.warnermedia.com/",
        "spoliers": false,
        "darkTitle": false,
    },
    {
        "name": "Only Murders in The Building Series 2 Review",
        "tv": "only-murders-in-the-building-series-2",
        "subtitle": "Fun, laughs & murder!",
        "date": "28/June/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "onlymurdersinthebuilding.jpg", text: "The Three Amigos", alt: "Martin Short, Steve Martin and Selena Gomez posing in a promotional manner"},
        "image_2": {image: "onlymurders2.jpeg", text: "Bloody Mabel", alt: "Selena Gomez as Mabel, posing for a police mugshot in a blood covered sweater"},
        "image_3": {image: "onlymurders3.jpeg", text: "The Three Amigos. Wait, we already used that joke.", alt: "Martin Short, Steve Martin and Selena Gomez in an apartment."},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Only Murders in The Building, streaming on Hulu (or Disney+ depending on where you are), just released its second season and we're loving it already." +
            " For anyone who hasn't heard of it, it's a comedy / mystery show starring an unlikely trio - Selena Gomez, Martin Short and Steve Martin. Short " +
            " and Martin have been comedy partners and collaborators for decades, but Only Murders is a clever mix of generations, with actress / popstar Gomez" +
            " partnering as the new third Amigo.",
        "paragraph_2":"If you haven't seen series 1, we'd suggest not reading further, as while we won't spoil anything" +
            " in series 2, series 1 is fair game.",
        "paragraph_3":"Ww love this show for a number of reasons, firstly - it's different. TV is now a mix of " +
            " 'prestige' shows with movie stars appearing in serious dramas, superhero or science fiction shows (Obi-Wan!)" +
            " and a whole lot of filler on the streamers. Only Murders is different however - a funny, witty show that doesn't fit" +
            " into existing genres or boxes. Selena Gomez is fantastic, playing a 'millenial' character, Mabel, that may have" +
            " been shallow in other shows, but here is a layered role - a combination of caustic putdowns to her older comrades," +
            " vulnerability and a compelling sense of loneliness. The two Martins are in great form, with Short obviously relishing" +
            " the opportunity to play another over the top flamboyant character, but again with layers and nuance.",
        "paragraph_4":"We also love this show because our teenage kids watch it with us! It's probably the mix of generations, but" +
            " also the fact it's just good - a nice mix of comedy, mystery & twists that keeps things moving along nicely." +
            " We also got to show them Father of the Bride 1 and 2, not telling them who starred in those and watching them" +
            " realise in real time! I listened to the Rewatchables podcast recently about Father of the Bride and it was" +
            " discussed that Franc, Martin Short's completely over the top wedding planner, wouldn't be considered funny today. " +
            " Tell that to my kids who absolutely loved him!",
        "paragraph_5":"At the end of Series 1 (we told you not to read if you haven't seen it!), Mabel is found, covered in blood" +
            " over the dead body of Bunny, the buildings' resident chairperson. Series 2 kicks off immediately afterwards with" +
            " our three amigos (ok, we'll definitely stop now) being interviewed by the police, with some great jokes and oneliners." +
            " We were afraid that the fickle daughters would inform us they no longer liked the show, but they ate it up," +
            " insisting we watch the first two episodes straight away. We've only seen those episodes (we're trying to figure " +
            " out how to become one of those sites that gets to watch shows before they come out but haven't gotten there yet!)" +
            " but so far it's been great, with plenty of new comedy and mystery, along with further character development & backstory," +
            " and a parrot who likes to swear almost as much as we do.",
        "paragraph_6":"In summary - if you're looking for a show to watch with your own older kids, we'd recommend this one, " +
            " and we'll watching every episode when they come out (it's weekly, not binge mode!).",
        "paragraph_7":"If you liked this post, please share and tell your friends! If you think you can do a better job reviewing," +
            " please get in touch, see the about page for more details on how you can write for NOadsHERE!",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"Disney",
        "spoliers": false,
        "darkTitle": false,
    },
    {
        "name": "Daredevil Re-Review (SPOILERS!)",
        "tv": "daredevil-re-review",
        "subtitle": "Probably our favourite superhero.",
        "date": "6/June/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "daredevil1.jpg", text: "Would you mess with this man? Vincent D'Onofrio as Wilson Fisk", alt: "Vincent D'Onofrio as Wilson Fisk"},
        "image_2": {image: "daredevil4.jpg", text: "Or this guy? Charlie Cox as Daredevil", alt: "Daredevil in costume"} ,
        "image_3": {image: "daredevil2.jpg", text: "Yes, he's a blind superhero. Charlie Cox & Deborah Ann Woll.", alt: "Matthew Murdock and Karen Page"} ,
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"While not a new show, we’ve recently started watching Daredevil again - a Marvel tv series that was first on Netflix and ran for 3 series. A great show, it’s early demise was likely due to the arrival of Disney+ and the end of licensing deals with Netflix. However - the main character, Matt Murdock (alter ego Daredevil), played again by Charlie Cox, had a cameo in the latest Spiderman movie, and Disney are apparently working on a new series. So when better than now to revisit the original show?",
        "paragraph_2":"Daredevil tells the tale of how Matt Murdock got his “powers” - he is blind (TRAGIC BACKSTORY ALERT!) but has dramatically enhanced other senses and an incredible ability to give and take a beating. And plenty of beatings there are - this 18-rated series is full of well-choreographed fight scenes, including several long running fights with multiple participants attacking Daredevil that are remarkable in their length and violence.",
        "paragraph_3":"It’s not however just a slug-fest, it’s a deep show with plenty of time spent on character development, with multiple strong and interesting characters. There’s Karen (Deborah Ann Woll) who we first meet holding a knife over a dead body, Foggy Nelson (Eldon Henson), Matt Murdock’s friend and law-firm partner, and most impressively Wilson Fisk aka Kingpin (Vincent D'Onofrio) - one of TV’s recent great bad guys, a huge hulking mastermind criminal, played excellently by D’Orofrio. The sheer menace and danger he exudes is remarkable, and such an effective villain, he recently also had a cameo on Disney+ - in the Hawkeye series, and we wouldn’t be surprised to see him again in future shows.",
        "paragraph_4":"You may wonder, as we sometimes do, if a traditional crime boss can ever compete in the villainy stakes in a universe that also has the likes of Thanos and Loki but D’Onofrio is fantastic in the role, both as a ruthless crime boss and as sheer physical force who can also mete out vicious beatings of his own when appropriate.",
        "paragraph_5":"Daredevil also appears in the Marvel Defender series - where he joins forces with heroes from the others Netflix Marvel series - Jessica Jones," +
            " Luke Cage and the Iron Fist. All those other shows are worth checking out, but our favourite is Daredevil, maybe because" +
            " it was the first, but maybe because it's the best.",
        "paragraph_6":"In summary - if you missed this show, check it out now before Disney+ brings out series 4!",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://twitter.com/Daredevil/",
        "spoliers": true,
        "darkTitle": true,
    },
    {
        "name": "Obi-Wan Kenobi Review (SPOILERS!)",
        "tv": "obi-wan-kenobi-review",
        "subtitle": "Who doesn't love lightsabers and Jedis?!",
        "date": "29/May/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "obiwan1.jpeg", text: "Ewan McGregor as Obi-Wan Kenobi", alt: "Ewan McGregor as Obi-Wan Kenobi"},
        "image_2": {image: "obiwan2.jpeg", text: "Moses Ingram as Reva", alt: "Moses Ingram as Reva"} ,
        "image_3": {image: "vader.jpg", text: "I AM YOUR FATHER!", alt: "Darth Vader"} ,
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Obi-Wan Kenobi is a limited 6-episode series on Disney+, focused on what Jedi knight Obi-Wan Kenobi got up to after Star Wars Episode 3. " +
            "If you can't remember what happened in the prequels, there's a handy recap at the start - " +
            "basically Anakin Skywalker (Obi-wan's protegé and friend, along with being Luke & Leia's dad) goes bad " +
            " and joins in the murder of pretty much all the Jedis. It should probably go without saying that you'd want" +
            " a bit of Star Wars knowledge to fully understand the context of the series, and to recognise returning characters from the movies. Anyway, spoilers ahead so leave now if you haven't seen it yet!",
        "paragraph_2":"Ewan McGregor is the heart of the show, his Obi-wan now a weary shadow of his former self, a quiet nobody working a boring job, " +
            " living in a cave and having nightmares of the downfall of Anakin. The success of the Mandalorian was due in large part to the performance of its main star, Pedro Pascal, and the same goes here. McGregor " +
            " plays the role with nuance, adept at the quiet regret-filled morose moments along with the action scenes once things start heating up.",
        "paragraph_3":"It wouldn't be Star Wars without some over-the-top evil villians and we've already seen a few - mainly Moses Ingram's Reva, a " +
            "force & light-saber using 'Inquisitor' AKA a Jedi-hunter. Casually lopping off people's hands with her light-saber, threatening" +
            " random farmers (actually not so random - it's Luke Skywakers's Uncle Owen, if only she knew!) in the hope of getting " +
            "information, she's just plain evil, and the sort of purely bad villian that shows like this need. At the end of the second episode however" +
            " Obi-wan finds out what we already know (as we've seen all the movies!) - that Anakin survived and is now the" +
            " ultimate villian, Darth Vader. I'm guessing Riva and Vader will be like bad cop, worse cop.",
        "paragraph_4":"The main surprise in the first two episodes (only two have been released at the time of writing) is the reason why Obi-wan" +
            " finally left his cave and re-entered the galaxy - little 10 year old Princess Leia has been kidnapped in an attempt to draw him out. The" +
            " bad folks don't seem to realise who she actually is, but her parents do (including her adoptive father Jimmy Smits returning from the prequel movies)" +
            " so they can't trust anyone else to rescue her except Obi-wan. Potentially trying to re-capture some of the Baby Yoda cuteness, she's" +
            " a typical precocious Disney princess, complete with little flying robot toy / companion that I'd suspect will be" +
            " on shelves this Christmas! Her introduction to the story gives it more depth and meaning, and also will likely inform" +
            " why Leia refers to him as 'old friend' in the original Star Wars movie.",
        "paragraph_5":"The only slight gripe we have with the show is that some of the special effects aren't as good" +
            " as you'd see in a movie, and some of the sets look too clean or fake, but we'll forgive them given the" +
            " overall quality, and that it is actually a tv series! All in all, it's not quite as good (yet) as the Mandalorian" +
            " but it's much better than the Book of Boba Fett. We're looking forward to the next episodes and following Obi-wan on his journey," +
            " and we're especially looking forward to him (and us) meeting Darth Vader again.",
        "paragraph_6":"Come back soon to read more reviews as we get to see more episodes!",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://dmedmedia.disney.com/disney-plus/obi-wan-kenobi/",
        "spoliers": false,
        "darkTitle": false
    },
    {
        "name": "Hard Cell Review",
        "tv": "hard-cell-review",
        "subtitle": "",
        "date": "17/May/22",
        "reviewer": {name:"Ellen Ward",twitter:"https://twitter.com/AlienWardrobe"},
        "rating": 4,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "catherinetate.jpg", text: "Catherine Tate"},
        "image_2": {image: "hardcell.jpg", text: "name that actress"},
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"I started watching Hard Cell because I wanted something quick and entertaining that didn't require thinking, and it is that plus a little bit more. Catherine Tate has written a comedy that really showcases her own talents (she plays multiple characters) in a setting which allows for lots of strong female characters, a women's prison. Not the funniest place in the world perhaps, but I think Tate could make people laugh waiting in line to pay a parking fine. She has a lot of laughs to give but this show is a bit different to her previous work.",
        "paragraph_2":"I was a fan of Prisoner Cell Block H as a university student but didn't realise at the time that it was one of the few female led dramas on TV. I just loved the stories and the high tension stakes - when someone can stab you at anytime, and you can't get away, life is pretty perilous. I learnt it is possible to like people who have done bad things, and years later I enjoyed this feeling again watching Orange is the New Black. OITNB gave us whole people, with complex characters and told us we could get invested in them, often just before they got killed or released. And unlike Prisoner Cell Block H, there was comedy mixed in there too, which worked really well.",
        "paragraph_3":"While Hard Cell does not have time to get into backstories or political themes, it is not asking you to think, which is lovely after a busy day. It does let you see a range of women who all have needs and hopes and voices, all of them are of course a bit far fetched but the viewer can still warm to them. Plus it is funny. There are some lovely one liners with looks to camera (like The Office) as well as a good splash of comedy added to each of the women and guards, although most of them don't know they are being funny. And at times you can almost forget which characters are played by Tate which means she is doing a good job (and a lot of make up and costume changes). ",
        "paragraph_4":"After watching the first series of Hard Cell my (admittedly low) expectations have been exceeded. As well as fun characters there were some interesting story lines which progressed nicely towards the finale, and this was somehow managed with very few actual screen minutes which is quite clever. What seems to be a bit of a laugh, actually has a heart and also works as a carefully choreographed dance of characters, much like the season finale musical. \"I'm excited\" for season 2. And you'll have to watch it now to get that reference!",
        "paragraph_5":"",
        "paragraph_6":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://media.netflix.com/",
        "spoliers": false,
        "darkTitle": false
    },
    {
        "name": "Moon Knight Review",
        "tv": "moon-knight-review",
        "subtitle": "Who am I talking to?",
        "date": "28/April/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "No thanks, you can watch that one with the girls",
        "kidsreview": "You made fun of my Spiderman review so I'm not doing one for this",
        "image_1": {image: "moonKnight2.jpg", text: "Oscar Isaac as Moon Knight / and a few others"},
        "image_2": {image: "moonKnight3.jpeg", text: "May Calamawy as Layla"},
        "image_3": {image: "moonKnight4.png", text: "Ethan Hawke as Arthur Harrow"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Moon Knight is a new limited (6 episode) Marvel tv series on Disney+, starring Oscar Isaac (Star Wars, Dune)" +
            " as Moon Knight, a superhero given his powers by the Egyptian god Khonshu, and also ... a couple of other characters." +
            " We really don't want to spoil anything, but it was fairly common knowledge before the show came out that Isaac's" +
            " character had multiple personalities. As the series progresses, we realise that the story is really about him," +
            " why his mind is as it is, and, as Robin in Teen Titans Go would say, his TRAGIC BACKSTORY! (Bonus points" +
            " for anyone who knows what I'm talking about there!)",
        "paragraph_2":"The series is obviously about more than just Isaac's character's struggles - the main plot involves Ethan Hawke's" +
            " Arthur Harrow attempting to resurrect a not-so-nice Egyptian god, and Moon Knight's efforts to stop him. The show is" +
            " slightly confusing to begin with, but we suspect that's on purpose, to give us a sense of what Isaac's character is going " +
            " through. There is plenty of action though - when he transforms into Moon Knight and hands out 'Khonshu's vengeance', " +
            " it's pretty cool!",
        "paragraph_3":"Although a Marvel show, there doesn't seem to be any mention of the wider Marvel universe, though it will" +
            " be interesting if Moon Knight ends up teaming up with other superheroes in any upcoming movies, given his unique" +
            " background and skill set - powers from an Egyptian god! We haven't seen the final episode yet however, so we're presuming" +
            " he survives!",
        "paragraph_4":"",
        "paragraph_5":"In summary, it's a good show and worth a watch - Oscar Isaac is brilliant, Ethan Hawke plays a great bad guy," +
            " and May Calamawy plays a character (no spoilers!) who can more than hold her own, even when fighting ancient Egyptian monsters!" +
            " (ok, so maybe slight spoilers!). There's action, fun, romance and more, what else would you want from a new Marvel show?",
        "paragraph_6":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://dmedmedia.disney.com/",
        "spoliers": false,
        "darkTitle": false
    },
    {
        "name": "Outer Range Review",
        "tv": "outer-range-review",
        "subtitle": "There is a void!",
        "date": "23/April/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 4,
        "wifereview": "No thanks",
        "kidsreview": "* Kids haven't seen it yet. It'd probably be suitable for teenagers *",
        "image_1": {image: "imogen_poots1.jpeg", text: "Imogen Poots as Autumn"},
        "image_2": {image: "openRange.png", text: "Tom Pelphrey, Lewis Pullman & Josh Brolin"},
        "image_3": {image: "theVoid.png", text: "Yes that is a big hole in the ground"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Outer Range is a new western/sci-fi series on Amazon Prime, fronted by Josh Brolin (No Country for Old Men," +
            " Dune, Thanos in Avengers movies!) that is very much in the 'a little bit strange' bucket, but we are" +
            " enjoying it.",
        "paragraph_2":"It's slightly difficult to discuss without giving any spoilers but we'll try our best! " +
            "The show is about a ranch in Wyoming and very much has a Yellowstone / western feel, however there is " +
            "a huge dark hole in a field - and lots of strange and wonderful things ensue!",
        "paragraph_3":"The acting is great - Josh Brolin as the main protagonist, Imogen Poots as a mysterious (when writing" +
            " about this show 'mysterious' comes up a lot!) woman who arrives at the ranch, Will Patton as a possibly" +
            " crazy rich neighbour, and many more characters of varying oddness.",
        "paragraph_4":"The oddness is perhaps our only slight gripe with the show - some characters seem a bit over the top" +
            " in their behaviour, whereas others, like Josh Brolin's are more grounded and realistic. The show maybe" +
            " didn't need eccentric characters for the story to work, but it's only a minor complaint, and we've " +
            " only seen four of the eight episodes so they may all make sense eventually!",
        "paragraph_5":"The show is a mix of genres - western, family drama, sci-fi mystery, and while that sounds" +
            " a bit strange, it works, and we're looking forward to the next four episodes. They are dropping" +
            " clues in each episode as to what the hell is going on, and we'd love to discuss some of the theories" +
            " we have but we'll leave them for a separate spoiler-review!",
        "paragraph_6":"In summary, it's worth a watch.",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://press.amazonstudios.com/",
        "spoliers": false,
        "darkTitle": false
    },
    {
        "name": "Reacher Series 1 Review",
        "tv": "reacher-series-1-review",
        "subtitle": "All he wants is some peach pie",
        "date": "12/Feb/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "I was actually quite enjoying it.",
        "kidsreview": "* Not suitable for kids - very violent with some nudity *",
        "image_1": {image: "reacher.jpg", text: "yes, this guy is huge"},
        "image_2": {image: "reacher1.JPG", text: "Willa Fitzgerald as Officer Roscoe"},
        "image_3": {image: "reacher2.JPG", text: "Yes, it's a bit too hot in Georgia for that suit"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Reacher is a new series on Amazon Prime, based on a long running book series by Lee Child. There were two recent movies based on the books - with Tom Cruise -" +
        " that weren’t that bad, but the series is a definite improvement.",
        "paragraph_2":"Jack Reacher is the main character - a former army “special investigator” who’s now" +
        " retired and travels the US with almost no possessions - no phone, no spare clothes," +
        " no car. Of course, as some childhood flashbacks show, trouble has a way of finding him," +
        " so when he stops off at a small town in Georgia, you know it’s not going to just be for some delicious peach pie.",
        "paragraph_3":"We won’t spoil any of the story, but it’s good - plenty of action, interesting characters, viciously" +
        " horrible bad guys (always a sign of a good show) and for what is basically an old school" +
        " “strange hero arrives in town and saves it” story, it has sufficient quality to keep you interested.",
        "paragraph_5":"Reacher is played by Alan Ritchson, and he seems born to play the part - he’s simply huge," +
        " a modern day Arnold Schwarzenegger, and while he, like a lot of Arnold roles, doesn’t say much," +
        " he isn’t simply a big slab of muscle. Like Arnie in his heyday, he has a magnetism that draws you in," +
        " with an undercurrent of judicious violence that you know can be meted out at any stage. ",
        "paragraph_6":"The first show we’ve binged in a long time, it’s worth a watch, and we" +
        " hope there’s many more series to come. One peculiar element of the books is that because Reacher drifts around the US, any new series will likely not have the same supporting characters" +
        " - with possibly some exceptions - so it’ll be interesting to see can future series be as good.",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://press.amazonstudios.com/",
        "spoliers": false,
        "darkTitle": false
       },
    {
        "name": "Wheel of Time Series 1 Review (SPOILERS!)",
        "tv": "wheel-of-time-series-1-review",
        "subtitle": "No beginnings, endings or ads!",
        "date": "27/April/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 3,
        "wifereview": "Not my cup of tea thanks",
        "kidsreview": "Looks ok but can we put on something else?",
        "image_1": {image: "wot_001.JPG", text: "Epic horseriding - check! Rosamund Pike as Moiraine"},
        "image_2": {image: "padanFain.png", text: "Darkfriend alert! Johann Myers as Padan Fain"},
        "image_3": {image: "wot_009.jpg", text: "Just the lads at ye old bar having pints"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"It’s been several months since The Wheel of Time debuted on Amazon Prime " +
            "so we’ve had a while to mull it over and think about how we feel about it, after the " +
            " initial excitement had faded. Overall, we’re happy it exists but are slightly " +
            " disappointed - so far. Amazon seems to be planning " +
            " multiple more series so we perhaps shouldn’t judge a first season too harshly. Major spoilers ahead so leave no if you haven't watched it all yet! SPOILERS!",
        "paragraph_2":"It is amazing to have such a beloved book series being given the streaming treatment, and " +
            "Amazon are clearly spending a lot of cash on the show. There’s lots of characters, " +
            "great looking sets and plenty of action, and the show is slowly revealing what is a massive world, " +
            "full of backstory and detail. The producers have also taken a fantastic approach to diversity - " +
            "with multiple characters played by actors & actresses with diverse backgrounds, and one of the main characters, Moiraine" +
            " in a queer relationship with the Amrylin Seat - the leader of the female-only 'Aes Sedai'. " +
            "Yes, the main protagonist is " +
            "likely going to be a white man - Rand. I say likely, as his identity as the “Dragon Reborn” was only " +
            "revealed at the end of the first series, and the show has made many changes already from the source material. " +
            "The majority of the main characters are not played by white actors, which is unusual for most TV shows.",
        "paragraph_3":"It's not all rosy though, we  do however have some concerns ...",
        "paragraph_4":"First - everything is just too clean! Game of Thrones was grimy and dirty, and it felt like it was real. " +
            "The Wheel of Time however is too clean, it needs to make things a bit more grubby and grimy, to make it more " +
            "believable. That might sound a little crazy about a show set in a magical fantasy world, but watching it, it all" +
            " just seems a bit too 'filmed in a studio'.",
        "paragraph_5":"Next - and we're going to sound like total fanboys - the show is diverging from the sources books, " +
            "and we're not convinced about the need for it. One of the main characters, Perrin, accidentally kills his wife, and also has feelings" +
            " for Egwene - neither of which are in the books. Perrin wasn't even married! Maybe the show producers felt they needed more personal drama or maybe" +
            " they have a grand plan, but I just wonder is it needed. Likewise, the ending of the series isn't the same as " +
            " the end of the book in a number of ways. Again, maybe we're fanboy-ing too much, but if the last season of Game of Thrones" +
            " taught us anything, it's that you risk going off the rails when you start diverging from the source material. Of course, with" +
            " GOT, the source material had run out, but it's almost the same! ",
        "paragraph_6":"In summary, it's a flawed show but it has so much potential that it's worth staying the course. People" +
            " expecting Game of Thrones 2 may be disappointed, but there's a fantastic world to be explored, and many many more" +
            " books to mine, as long as they don't stray too far! We'll be watching every episode, check back here for more reviews" +
            " and discussion. If you'd like to post your own review - get in touch and let us know!",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://press.amazonstudios.com/",
        "spoliers": true,
        "darkTitle": false
    },
    {
        "name": "Why The Mentalist Is One Of The Best Shows Of All Time",
        "tv": "mentalist-best-show-ever",
        "subtitle": "RED JOHN!!!!!",
        "date": "30/April/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "Ah, Patrick Jane!",
        "kidsreview": "It's amazing",
        "image_1": {image: "mentalist.jpg", text: "Pick a card, any card", alt:"Kimball Cho (Tim Kang) and Patrick Jane (Simon Baker)"},
        "image_2": {image: "patrickjane.jpg", text: "Would you trust this man?",alt: "Simon Baker as Patrick Jane"},
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"The Mentalist isn't a new show - it ran for 151 episodes from   2008 to 2015 but it's one of my all-time favourites" +
            " and I've watched it fully three times - twice with my wife, and recently one more time with my older kids." +
            " For folks who've never seen it, it's about Patrick Jane - a former celebrity psychic and con-man who now helps the police" +
            " solve murders. Not using psychic skills - which he admits were always fake - but with his reasoning, intelligence" +
            " and ability to easily manipulate people! He's motivated to help the police after the murder of his wife and daughter by a serial killer" +
            " called Red John, whom he insulted on television.",
        "paragraph_2":"If that sounds a bit dark, it can be at times, but the show is a great mixture of traditional procedural" +
            " crime-of-the-week cop show, with plenty of humour and fun, but also with a serious undertone as Patrick Jane hunts for" +
            " his family's killer. Played by Simon Baker, Jane (as he's referred too - a classic cop show trope of everyone being" +
            " called by their surname!) is a nuanced character who bitterly regrets his past actions which led to the murder of " +
            " his loved ones, but who also has a mischievous smirk and loves nothing more than using trickery to catch out a killer.",
        "paragraph_3":"Red John is one of television's best bad guys, whose menacing impact influences the show more" +
            " and more as it progresses. I don't want to say too much as I don't want to spoil anything, but even on the third viewing with my " +
            " kids, the more Red John focused episodes were always my favourite. There are also other strong characters - Teresa Lisbon" +
            " (Robin Tunney) as a detective in the CBI (California Bureau of Investigation) and her team - Kimball Cho (Tim Kang)" +
            " Grace Van Pelt (Amanda Righetti) and more. ",
        "paragraph_4":"All in all it's a fantastic show, driven by a great TV character, Patrick Jane, whose charm, sneakiness," +
            " trickery and thirst for revenge make pretty much every episode entertaining. Rewatching it with my kids was almost" +
            " as fun as watching it for the first time - watching them be amazed at the twists and turns, listening to them wonder" +
            " who Red John really is, and what Jane will do if he ever manages to catch him.",
        "paragraph_5":"We couldn't make this site without a post on the Mentalist, and hopefully if there's folks out there who" +
            " haven't seen it, they may be inspired to try it out. We're off now to watch it again!",
        "paragraph_6":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"https://www.warnerbros.com/tv/mentalist",
        "spoliers": false,
        "darkTitle": false
    },
    ];