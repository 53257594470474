/**
 * Created by andyosullivan on 04/04/2021.
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from './containers/Home/Home';
import Construction from './containers/Construction/Construction';
import About from './containers/About/About';
import Movies from './containers/Movies/Movies';
import Recipes from './containers/Recipes/Recipes';
import TV from './containers/TV/TV';
import Books from './containers/Books/Books';

export default function Routes(){
    return (

        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/construction" exact component={Construction} />
            <Route path="/movies/:movie" exact component={Movies} />
            <Route path="/movies/" exact component={Movies} />
            <Route path="/recipes/:recipe" exact component={Recipes} />
            <Route path="/recipes/" exact component={Recipes} />
            <Route path="/tv/:tv" exact component={TV} />
            <Route path="/tv/" exact component={TV} />
            <Route path="/books/:book" exact component={Books} />
            <Route path="/books/" exact component={Books} />
        </Switch>
    );
}


