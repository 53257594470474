/**
 * Created by andyosullivan on 20/06/2021.
 */
import React, { Component } from "react";
import "../Home/Home.css";
import "../About/About.css";
import "../Movies/Movies.css";
import "./Recipes.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import {withRouter} from 'react-router-dom';
import { recipe_details } from '../../assets/recipes/recipe_details';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import twitter from "../../assets/images/twitter.png";

class Recipes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: {
                "name": "test",
                "recipe": "test-recipe",
                "date": "11/Jan/22",
                "reviewer":{name:"Test",twitter:"test twitter"},
                "wifereview": "test",
                "kidsreview": "test",
                "image_1": "dune001.jpg",
                "image_2": "dune001.jpg",
                "image_3": "dune001.jpg",
                "image_4": "dune001.jpg",
                "image_5": "dune001.jpg",
                "image_6": "dune001.jpg",
                "paragraph_1":"test",
                "paragraph_2":"test",
                "paragraph_3":"test",
                "paragraph_5":"test ",
                "paragraph_6":"test",
                "paragraph_7":"",
                "paragraph_8":"",
                "paragraph_9":"",
                "paragraph_10":"",
                "ingredients":["test","test"],
                "image_sources":""
            },
            recipes: [],
            allRecipes: true
        };
        this.filterArray = this.filterArray.bind(this);
    }

    componentDidMount () {
        this.deleteAllCookies();

        const recipe = this.props.match.params.recipe;
        if (recipe === "all") {
            this.setState({"allRecipes":true});
        } else {
            let result = (recipe_details.filter(function (o) {
                return o.recipe === recipe;
            }));
            this.setState({recipe: result[0]});
            this.setState({"allRecipes":false});
            this.setState({url: "https://twitter.com/intent/tweet?text=Check%20out%20this%20recipe%20on%20https://NOadsHERE.com/recipes/"});

        }
        this.setState({recipes: recipe_details});
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear();
        sessionStorage.clear();
    }

    filterArray(e){
        var searched = recipe_details.filter(({recipe}) => recipe.includes(e.target.value.toLowerCase()));
        this.setState({recipes: searched});
    }


    render() {
        let temp = false;
        let temp2 = false;
        let temp3 = false;
        let temp4 = false;
        try {
            if (require("../../assets/images/" + this.state.recipe.image_2.image)) {
                temp = true;
            }
        }catch (error){
            temp = false;
        }
        try {
            if (require("../../assets/images/" + this.state.recipe.image_3.image)) {
                temp2 = true;
            }
        }catch (error){
            temp2 = false;
        }
        try {
            if (require("../../assets/images/" + this.state.recipe.image_4.image)) {
                temp3 = true;
            }
        }catch (error){
            temp3 = false;
        }
        try {
            if (require("../../assets/images/" + this.state.recipe.image_5.image)) {
                temp4 = true;
            }
        }catch (error){
            temp4 = false;
        }

        return (
            <div className="divmor">
                <Container>

                    <Row className="movieRow">
                        {!this.state.allRecipes &&
                        <div>
                            <Col className="" sm={12} lg={8}>
                                <div className="movieDiv">
                                    <h1 className="darkGrey">{this.state.recipe.name}<br/>
                                        <span className="movieDate">{this.state.recipe.date} - {this.state.recipe.reviewer.twitter && <a href={this.state.recipe.reviewer.twitter} rel="noopener noreferrer" target="_blank">{this.state.recipe.reviewer.name}</a>}{!this.state.recipe.reviewer.twitter && this.state.recipe.reviewer.name}</span></h1>

                                    <img className="movieImage"
                                         src={require("../../assets/images/" + this.state.recipe.image_1.image).default}
                                         alt={this.state.recipe.image_1.text}/>
                                    <figcaption className="fig"><h4>{this.state.recipe.image_1.text}</h4></figcaption>
                                    <br/>
                                    <p className="movieBlurb"> {this.state.recipe.paragraph_1}
                                        <br/>
                                    </p>
                                    <p className="movieBlurb"><strong>Ingredients:</strong>
                                    </p>
                                    {Array.from( this.state.recipe.ingredients ).map((item) => (
                                            <p className="movieBlurb"> - {item}</p>
                                        )
                                    )}
                                    <p className="movieBlurb"><strong>Method:</strong></p>
                                    <p className="movieBlurb"> {this.state.recipe.paragraph_2}</p>
                                    {temp &&
                                        <div>
                                            <img className="movieImage"
                                                 src={require("../../assets/images/" + this.state.recipe.image_2.image).default}
                                                 alt={this.state.recipe.image_2.text}/>
                                            <figcaption className="fig"><h4>{this.state.recipe.image_2.text}</h4></figcaption>
                                            <br/>
                                        </div>
                                    }

                                    <p className="movieBlurb"> {this.state.recipe.paragraph_3}</p>
                                    <p className="movieBlurb"> {this.state.recipe.paragraph_4}</p>
                                    {temp2 &&
                                    <div>
                                        <img className="movieImage"
                                             src={require("../../assets/images/" + this.state.recipe.image_3.image).default}
                                             alt={this.state.recipe.image_3.text}/>
                                        <figcaption className="fig"><h4>{this.state.recipe.image_3.text}</h4></figcaption>
                                        <br/>
                                    </div>
                                    }

                                    <p className="movieBlurb"> {this.state.recipe.paragraph_5}</p>
                                    <p className="movieBlurb"> {this.state.recipe.paragraph_6}</p>
                                    {temp3 &&
                                        <div>
                                            <img className="movieImage"
                                                 src={require("../../assets/images/" + this.state.recipe.image_4.image).default}
                                                 alt={this.state.recipe.image_4.text}/>
                                            <figcaption className="fig"><h4>{this.state.recipe.image_4.text}</h4></figcaption>
                                            <br/>
                                        </div>
                                    }
                                    {this.state.recipe.paragraph_7 && <p className="movieBlurb"> {this.state.recipe.paragraph_7}</p>}
                                    {this.state.recipe.paragraph_8 && <p className="movieBlurb"> {this.state.recipe.paragraph_8}</p>}
                                    {this.state.recipe.paragraph_9 && <p className="movieBlurb"> {this.state.recipe.paragraph_9}</p>}
                                    {temp4 &&
                                        <div>
                                            <img className="movieImage"
                                                 src={require("../../assets/images/" + this.state.recipe.image_5.image).default}
                                                 alt={this.state.recipe.image_5.text}/>
                                            <figcaption className="fig"><h4>{this.state.recipe.image_5.text}</h4></figcaption>
                                            <br/>
                                        </div>
                                    }
                                    {this.state.recipe.paragraph_10 && <p className="movieBlurb"> {this.state.recipe.paragraph_10}</p>}
                                    {this.state.recipe.paragraph_11 && <p className="movieBlurb"> {this.state.recipe.paragraph_11}</p>}
                                    <p className="movieBlurb"><strong>Share this recipe on:</strong></p>
                                    <a className="twitter-share-button" rel="noopener noreferrer" target="_blank"
                                       href={this.state.url + this.state.recipe.recipe}>
                                        <img className="social shareImage" src={twitter} alt="Twitter logo"/></a>
                                    <br/>
                                    <br/>
                                    <h4 className="shareTextTV"><strong>Sharing on Facebook coming soon.</strong></h4>
                                    <br/>
                                    <br/>
                                </div>
                            </Col>
                        </div>
                        }
                        {this.state.allRecipes &&
                        <div>

                            <Col className="" sm={12} lg={12}>
                                <div className="movieDiv">
                                    <Row>
                                        <Col className="" sm={4} lg={8}>
                                            <h1 className="darkGrey">Recipes</h1>
                                            <h4>Recipes are the main reason we built this site. Have you ever wanted to look up a quick recipe
                                            on your phone, only to find every site covered in ads, videos and unnecessary backstories so they can
                                            add more ads in? Us too, so this part of the site has no ads, just recipes!</h4>
                                            <br/>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col className="" sm={4} lg={4}>
                                        <Form.Group className="">
                                            <Form.Control className="" type="input" id="searchId" placeHolder="Search recipes" onChange={this.filterArray}/>
                                        </Form.Group>
                                    </Col>
                                    </Row>
                                    <Row className="allRow">
                                        {Array.from( this.state.recipes).map((item) => (
                                                <Col className="tvCol" sm={4} lg={4}>
                                                    <div className="bgImageDiv" style={{backgroundImage: "url(" + require("../../assets/images/" + item.image_1.image).default,  backgroundSize: "cover"}}>
                                                        <Card className="contentCard tv" key={item.name}>
                                                            <a className="cardLink" href={"/recipes/" + item.recipe} >
                                                                <p className="cardLinkText">{item.name}</p></a>
                                                        </Card>
                                                    </div>
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </div>
                            </Col>
                        </div>
                        }
                    </Row>
                </Container>
                <div className="footerAbout">
                    <a className="footerLinks" href="/">home</a>
                    <a className="footerLinks" href="/tv/all">tv</a>
                    <a className="footerLinks" href="/movies/all">movies</a>
                    <a className="footerLinks" href="/recipes/all">recipes</a>
                    <a className="footerLinks" href="/about/">about</a>
                </div>
            </div>
        );
    }
}

export default withRouter(Recipes);

