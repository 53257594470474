export const book_details = [
    {
        "name": "The Lies of Locke Lamora",
        "book": "the-lies-of-locke-lamora",
        "author": {name:"Scott Lynch",twitter:"https://twitter.com/scottlynch78"},
        "subtitle": "",
        "date": "20/July/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
        "rating": 5,
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "", text: "", alt: ""},
        "image_2": {image: "", text: "", alt: ""} ,
        "image_3": {image: "", text: "", alt: ""} ,
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Welcome to our first book review here at NOadsHERE.com, all about a book so good that each time" +
            " we pick it up to re-read, we remember all over again why we like it so much.",
        "paragraph_2":"",
        "paragraph_3":"",
        "paragraph_4":"",
        "paragraph_5":"",
        "paragraph_6":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "image_sources":"",
        "spoliers": true,
        "darkTitle": true,
    },
];