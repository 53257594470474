/**
 * Created by andyosullivan on 20/06/2021.
 */
import React, { Component } from "react";
import "../Home/Home.css";
import "../About/About.css";
import "../Movies/Movies.css";
import "../Recipes/Recipes.css";
import "../TV/TV.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import {withRouter} from 'react-router-dom';
import { book_details } from '../../assets/books/book_details';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import twitter from "../../assets/images/twitter.png";


class Books extends Component {
    constructor(props) {
        super(props);
        this.state = {
            book: {
                "name": "test",
                "book": "test-recipe",
                "date": "11/Jan/22",
                "reviewer":{name:"Test",twitter:"test twitter"},
                "image_1": "dune001.jpg",
                "image_2": "dune001.jpg",
                "image_3": "dune001.jpg",
                "image_4": "dune001.jpg",
                "image_5": "dune001.jpg",
                "image_6": "dune001.jpg",
                "paragraph_1":"test",
                "paragraph_2":"test",
                "paragraph_3":"test",
                "paragraph_5":"test ",
                "paragraph_6":"test",
                "paragraph_7":"",
                "paragraph_8":"",
                "paragraph_9":"",
                "paragraph_10":"",
                "darkTitle":false,
            },
            books: [],
            allBooks: true
        };
        this.filterArray = this.filterArray.bind(this);
    }

    componentDidMount () {
        this.deleteAllCookies();

        const book = this.props.match.params.book;
        if (book === "all") {
            this.setState({"allBooks":true});
        } else {
            let result = (book_details.filter(function (o) {
                return o.book === book;
            }));
            this.setState({book: result[0]});
            this.setState({"allBooks":false});
            this.setState({url: "https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20on%20https://NOadsHERE.com/books/"});

        }
        this.setState({books: book_details});
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear();
        sessionStorage.clear();
    }

    filterArray(e){
        var searched = book_details.filter(({book}) => book.includes(e.target.value.toLowerCase()));
        this.setState({books: searched});
    }

    render() {
        let temp = false;
        let temp2 = false;
        try {
            if (require("../../assets/images/tv/" + this.state.book.image_2.image)) {
                temp = true;
            }
        }catch (error){
            temp = false;
        }
        try {
            if (require("../../assets/images/tv/" + this.state.book.image_3.image)) {
                temp2 = true;
            }
        }catch (error){
            temp2 = false;
        }

        return (
            <div className="divmor">
                <Container>
                    <Row className="movieRow">
                        {!this.state.allBooks &&
                            <div>
                                <Col className="" sm={12} lg={8}>
                                    <div className="movieDiv">
                                        <h1 className="darkGrey">{this.state.book.name}<br/>
                                            <span className="movieDate">{this.state.book.date} - {this.state.book.reviewer.twitter && <a href={this.state.book.reviewer.twitter} rel="noopener noreferrer" target="_blank">{this.state.book.reviewer.name}</a>}{!this.state.book.reviewer.twitter && this.state.book.reviewer.name}</span></h1>
                                        <figure>
                                            <img className="movieImage"
                                                 src={require("../../assets/images/tv/" + this.state.book.image_1.image).default}
                                                 alt={this.state.book.image_1.text}/>
                                            <figcaption className="fig"><h4>{this.state.book.image_1.text}</h4></figcaption>
                                        </figure>
                                        <br/>
                                        <p className="movieBlurb"> {this.state.book.paragraph_1}
                                            <br/>
                                        </p>
                                        <p className="movieBlurb"> {this.state.book.paragraph_2}</p>
                                        <br/>
                                        {temp &&
                                            <div>
                                                <figure>
                                                    <img className="movieImage"
                                                         src={require("../../assets/images/tv/" + this.state.book.image_2.image).default}
                                                         alt={this.state.book.image_2.text}/>
                                                    <figcaption className="fig"><h4>{this.state.book.image_2.text}</h4></figcaption>
                                                    <br/>
                                                </figure>
                                            </div>
                                        }
                                        {this.state.book.paragraph_3 && <p className="movieBlurb"> {this.state.book.paragraph_3}</p>}
                                        {this.state.book.paragraph_4 && <p className="movieBlurb"> {this.state.book.paragraph_4}</p>}

                                        {temp2 &&
                                            <div>
                                                <br/>
                                                <figure>
                                                    <img className="movieImage"
                                                         src={require("../../assets/images/tv/" + this.state.book.image_3.image).default}
                                                         alt="whatever"/>
                                                    <figcaption className="fig"><h4>{this.state.book.image_3.text}</h4></figcaption>
                                                    <br/>
                                                </figure>
                                            </div>
                                        }
                                        {this.state.book.paragraph_5 && <p className="movieBlurb"> {this.state.book.paragraph_5}</p>}
                                        {this.state.book.paragraph_6 && <p className="movieBlurb"> {this.state.book.paragraph_6}</p>}
                                        <p className="movieBlurb"><strong>Rating:</strong> {this.state.book.rating} stars</p>
                                        <p className="movieBlurb sources"><i>{this.state.book.image_sources}</i> </p>
                                        <p className="movieBlurb"><strong>Share this post on:</strong></p>
                                        <a className="twitter-share-button" rel="noopener noreferrer" target="_blank"
                                           href={this.state.url + this.state.book.book}>
                                            <img className="social shareImage" src={twitter} alt="Twitter logo"/></a>
                                        <br/>
                                        <br/>
                                        <h4 className="shareTextTV"><strong>Sharing on Facebook coming soon.</strong></h4>
                                        <br/>
                                        <br/>
                                    </div>
                                </Col>
                            </div>
                        }
                        {this.state.allBooks &&
                            <div>

                                <Col className="" sm={12} lg={12}>
                                    <div className="movieDiv">
                                        <Row>
                                            <Col className="" sm={4} lg={8}>
                                                <h1 className="darkGrey">Books</h1>
                                                <h4>We've finally added a book review section!</h4>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="" sm={4} lg={4}>
                                                <Form.Group className="">
                                                    <Form.Control className="" type="input" id="searchId" placeHolder="Search books" onChange={this.filterArray}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="allRow">
                                            {Array.from( this.state.books ).map((item) => (
                                                    <Col className="tvCol" sm={4} lg={4}>
                                                        <div className="bgImageDiv" style={{backgroundImage: "url(" + require("../../assets/images/tv/" + item.image_1.image).default,  backgroundSize: "cover"}}>
                                                            <Card className="contentCard tv" key={item.name}>
                                                                {!item.darkTitle && <a className="cardLink" href={"/books/" + item.book} >
                                                                    <p className="cardLinkText">{item.name}</p></a>}
                                                                {item.darkTitle && <a className="cardLink" href={"/books/" + item.book} >
                                                                    <p className="cardLinkTextDark">{item.name}</p></a>}
                                                            </Card>
                                                        </div>
                                                    </Col>
                                                )
                                            )}
                                        </Row>
                                    </div>
                                </Col>
                            </div>
                        }
                    </Row>
                </Container>
                <div className="footerAbout">
                    <a className="footerLinks" href="/">home</a>
                    <a className="footerLinks" href="/tv/all">tv</a>
                    <a className="footerLinks" href="/movies/all">movies</a>
                    <a className="footerLinks" href="/recipes/all">recipes</a>
                    <a className="footerLinks" href="/about/">about</a>
                </div>
            </div>
        );
    }
}

export default withRouter(Books);

