/**
 * Created by andyosullivan on 04/04/2021.
 */
import React, { Component } from "react";
import "./Home.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import rightArrowBlack from '../../assets/images/rightArrowBlack.png';
import Card from "react-bootstrap/Card";
import { tv_details } from '../../assets/tv/tv_details';
import { movie_details } from '../../assets/movies/movie_details';
import { recipe_details } from '../../assets/recipes/recipe_details';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tv: {
                "heroText": "The new Star Wars show is unexpectedly dark, and is 100% not for the kids, so" +
                    " cancel that family tv night!",
                "title": "The Acolyte - Star Wars but Vicious (SPOILERS!)",
            "name": "The Acolyte - Star Wars but Vicious (SPOILERS!)",
            "tv": "the-acolyte-star-wars-but-vicious-spoilers",
            "subtitle": "Not for the kids",
            "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
            "rating": 4,
            "wifereview": "",
            "kidsreview": "",
            "date": "27/June/24",
            "image_1": {image: "acolyte_002.jpg", text: "You know they're bad when the saber is red", alt: ""},
            "spoliers": true,
            "darkTitle": false,
            "summary": "Not for the kids."
        },
            tvEpisodes: [],
            allTV: true,
            movie: {
                "tv": "family-movie-night-signs",
                "spoliers": true,
                "heroText": "A classic, scary family movie from 2002, re-reviewed for our Family Movie Night series.",
                "allMovies": true,
                "title": "Family Movie Review - Signs",
                "summary": "A classic, scary family movie",
                "date": "23/Aug/24",
                "reviewer": {name:"Andy O'Sullivan",twitter:"https://twitter.com/LeMarquisOfAndy"},
                "name": "Family Movie Night - Signs",
                "movie": "family-movie-night-signs",
                "subtitle": "In our top ten movies of all time",
                "image_1": {image:"signs_003.jpg", text:"",alt:""},
            },
            movies: [],
            recipe: {
                "name": "test",
                "recipe": "test-recipe",
                "date": "11/Jan/22",
                "reviewer": "Andy O'Sullivan",
                "wifereview": "test",
                "kidsreview": "test",
                "image_1": "dune001.jpg",
                "image_2": "dune001.jpg",
                "image_3": "dune001.jpg",
                "image_4": "dune001.jpg",
                "image_5": "dune001.jpg",
                "image_6": "dune001.jpg",
                "paragraph_1":"test",
                "paragraph_2":"test",
                "paragraph_3":"test",
                "paragraph_5":"test ",
                "paragraph_6":"test",
                "paragraph_7":"",
                "paragraph_8":"",
                "paragraph_9":"",
                "paragraph_10":"",
                "ingredients":["test","test"],
            },
            recipes: [],
        };
    }

    componentDidMount () {
        this.deleteAllCookies();
        this.setState({tvEpisodes: tv_details.slice(0,8), movies: movie_details.slice(0,8), recipes: recipe_details.slice(0,5)});
        //this.setState({movie: movie_details[0]});
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear()
        sessionStorage.clear();
    }

    render() {
        return (
            <div className="divmor">
                <Container>

                    <Row className="blurbRow">
                        <br/>
                        <Row className="cardRow">
                            <Col className="" sm={8}>
                            <div className="heroImageDiv" style={{backgroundImage: "url(" + require("../../assets/images/" + this.state.movie.image_1.image).default,  backgroundSize: "cover"}}>
                                </div>
                            </Col>
                            <Col className="" sm={4}>
                                <p className="blurbTitle"><span className="titleSpan">{this.state.movie.title} </span><br/>
                                    {this.state.movie.heroText} <a className="aboutLink" href={"/movies/" + this.state.movie.tv}><strong>Read the review</strong></a>.</p>
                            </Col>


                            <Col className="latestTV" sm={12}><h1 className="sectionTitle">Latest TV <a className="aboutLink" href="/tv/all">(or see all) </a></h1></Col>
                            {Array.from( this.state.tvEpisodes ).map((item) => (
                                    <Col className="tvCol" sm={4} lg={4}>
                                        <div className="bgImageDiv" style={{backgroundImage: "url(" + require("../../assets/images/tv/" + item.image_1.image).default,  backgroundSize: "cover"}}>
                                            <Card className="contentCard tv" key={item.name}>
                                                {!item.darkTitle && <a className="cardLink" href={"/tv/" + item.tv} >
                                                    <p className="cardLinkText">{item.name}</p></a>}
                                                {item.darkTitle && <a className="cardLink" href={"/tv/" + item.tv} >
                                                    <p className="cardLinkTextDark">{item.name}</p></a>}
                                            </Card>
                                        </div>
                                        <p className="tileText">{item.summary}</p>
                                    </Col>
                                )
                            )}
                            <Col className="" lg={4}>
                                <p className="blurbTitle"><span className="titleSpan">NOadsHERE.com </span>
                                    is a little corner of the web with Movie & TV Reviews, Recipes & more, with no ads,
                                    no user tracking, no cookies, no likes, no comments and no clickbait headlines. Just good content,
                                    privacy ...
                                    and nacho cheese sauce recipes. More info in <a className="aboutLink" href="/about"><strong>about</strong></a>.</p>
                            </Col>



                        </Row>

                        <Row className="cardRow">

                            <Col className="movieSection" sm={12}>

                                <h1 className="sectionTitle">Latest Movies <a className="aboutLink" href="/movies/all">(or see all) </a></h1></Col>

                            {Array.from( this.state.movies).map((item) => (
                                    <Col className="tvCol" sm={4} lg={4}>
                                        <div className="bgImageDiv" style={{backgroundImage: "url(" + require("../../assets/images/" + item.image_1.image).default,  backgroundSize: "cover"}}>
                                            <Card className="contentCard tv" key={item.name}>
                                                <a className="cardLink" href={"/movies/" + item.movie} >
                                                    <p className="cardLinkText">{item.name}</p></a>
                                            </Card>
                                        </div>
                                        <p className="tileText">{item.summary}</p>
                                    </Col>
                                )
                            )}
                            <Col className="" lg={4}>
                                <div className="steakDiv">
                                    <p className="writeTitle">want to <span className="titleSpan">write with us? </span>
                                        We want folks to help grow noadshere.com - to make it somewhere great and full of content, all free of ads, tracking
                                        or anything like that!</p>
                                    <a className="arrowIcon" href="/about"><img src={rightArrowBlack} alt="right arrow black color"/></a>
                                </div>
                            </Col>



                        </Row>



                        <Row className="cardRow endRecipes">
                            <Col className="" sm={8}>
                            </Col>
                            <Col className="" sm={12}><h1 className="sectionTitle">Latest  Recipes <a className="aboutLink" href="/recipes/all">(or see all) </a></h1></Col>
                            {Array.from( this.state.recipes).map((item) => (
                                    <Col className="tvCol" sm={4} lg={4}>
                                        <div className="bgImageDiv" style={{backgroundImage: "url(" + require("../../assets/images/" + item.image_1.image).default,  backgroundSize: "cover"}}>
                                            <Card className="contentCard tv" key={item.name}>
                                            <a className="cardLink" href={"/recipes/" + item.recipe} >
                                                <p className="cardLinkText">{item.name}</p></a>
                                            </Card>
                                        </div>
                                    </Col>
                                )
                            )}
                            <br/><br/>
                            <h2 className="copyright">copyright Andy O'Sullivan 2024</h2>
                            <br/>
                        </Row>
                    </Row>
                </Container>
                <div className="footerAbout">
                    <a className="footerLinks" href="/">home</a>
                    <a className="footerLinks" href="/tv/all">tv</a>
                    <a className="footerLinks" href="/movies/all">movies</a>
                    <a className="footerLinks" href="/recipes/all">recipes</a>
                    <a className="footerLinks" href="/about/">about</a>
                </div>
            </div>
        );
    }
}

/*




Home

About

Recipes

Movie Reviews

App Development

Serverless ?

 */