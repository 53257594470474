import React from "react";
import './App.css';
import Routes from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

function App() {
    return (
        <div className="App  bg-color">
            <Navbar collapseOnSelect expand="lg" className="nv" style={{ backgroundColor: '#61dafb'}}>
                <Navbar.Brand className="navBrand" href="/">NOadsHERE.com</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="navbar-expand-lg">
                    <Nav className="me-auto navMenu">
                        <br/>
                        <Nav.Link href="/tv/all">TV</Nav.Link>
                        <Nav.Link href="/movies/all">MOVIES</Nav.Link>
                        <Nav.Link href="/recipes/all">RECIPES</Nav.Link>
                        <Nav.Link href="/about">ABOUT</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
          <Routes />
        </div>
    );
}

export default App;
